import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Alert, Avatar, Box, Button, CircularProgress, Container, FormHelperText, Grid, MenuItem, Modal, Pagination, Select, SelectChangeEvent, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { dropService } from '../../clients/backend/drops';
import { searchService } from '../../clients/backend/search';
import DropCard from '../../components/drops/card';
import NftCard from '../../components/nfts/card';
import UserCard from '../../components/users/card';
import { useAppSelector } from '../../hooks';
import { Drop, NFT, User } from '../../types/api/types';
import { brandColors, gradients } from '../../utils/constants/colors';
import {Helmet} from "react-helmet";


const useStyles = makeStyles({
   
});

export default function SearchIndex() {
    const user = useAppSelector( (state) => state.counter.user);
    let classes = useStyles();

    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [pageErrors, setPageErrors] = useState<string[]>([]);
    const [pageLimit, setPageLimit] = useState<number>(50);
    const [pageOffset, setPageOffset] = useState<number>(1);

    const [drops, setDrops] = useState<Drop[]>([]);
    const [totalSearchDropCount, setTotalSearchDropCount] = useState<number | undefined>(undefined);

    const [minters, setMinters] = useState<User[]>([]);
    const [nfts, setNfts] = useState<NFT[]>([]);

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [tabIndex, setTabIndex] = useState<string>("0");

    const loadSearchData = async () => {
        setLoadingData(true);
        setPageErrors([]);

        searchService.search(searchQuery).then( (res) => {
            setDrops(res.result.data.drops);
            setTotalSearchDropCount(res.result.data.drops.length);

            setMinters(res.result.data.users);
            setNfts(res.result.data.nfts);
        }).catch( (error) => {
            setPageErrors(["Unable to load search data. Please refresh the page and try again."]);
        }).finally( () => {
            setLoadingData(false);
        });
    };

    useEffect( () => {
        loadSearchData();
    }, []);

    useEffect( () => {
        loadSearchData();
    }, [pageLimit, pageOffset])

    
    return (
        <Container>
            <Helmet>
                <title>Momentous - Beta :: Explore</title>
                <meta name="description" content="Momentous - NFTs for everyone" />
                <meta name="og:title" content="Momentous - beta :: Explore" />
                <meta name="og:description" content="Momentous - NFTs for everyone. Mint, Share, and Claim your favorite NFTs instantly with Momentous!" />
            </Helmet>
            {pageErrors.length > 0 && (
                pageErrors.map( (e) => (
                    <Alert severity="error" style={{marginTop: 20}}>{e}</Alert>
                ))
            )}
            
            <Modal open={loadingData}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'rgba(0,0,0,0.8)',
                    border: '2px solid #000',
                    boxShadow: 24,
                    color: 'white',
                    p: 4,
                    textAlign: 'center',
                    alignItems: 'center'
                    }}>
                        <Typography variant="body1">Refreshing Search Data</Typography>
                        <br/>
                        <CircularProgress />
                </Box>
            </Modal>

            <Typography style={{
                fontFamily: 'DWS Italic', marginTop:"20px"
            }} variant="h3" component="h1">
                Explore 
            </Typography>

            <Box style={{alignItems: 'center', margin: 20, flexDirection: 'row', display: 'flex', padding: 5}} >
                <Box style={{flex: 1, margin: 5}}>
                    <TextField variant="outlined" label="Search" color="secondary"
                        onBlur={loadSearchData}
                        onKeyPress={(e) => {
                            if(e.key.toLowerCase() == 'enter') {
                                loadSearchData();
                            }
                        }}
                        defaultValue={searchQuery}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        fullWidth={true}
                    />
                </Box>
            </Box>

            <Box>
                <TabContext value={tabIndex}>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center'}}>

                        <TabList centered indicatorColor="secondary" 
                            onChange={(_, nti) => setTabIndex(nti)}
                            style={{
                                backgroundColor: 'black',
                                borderRadius: 5,
                                color: 'white',
                                fontWeight: 'bold',
                                fontFamily: 'Druk Wide Heavy'
                            }}
                            textColor="inherit"
                        >
                            <Tab label={`Creations (${drops.length})`} value="0" style={{
                                fontFamily: 'Druk Wide Heavy'
                            }}></Tab>
                            <Tab label={`Users (${minters.length})`} value="1" style={{
                                fontFamily: 'Druk Wide Heavy'
                            }}></Tab>
                            {/* <Tab label={`NFTs (${nfts.length})`} value="2" style={{
                                fontFamily: 'Druk Wide Heavy'
                            }}></Tab> */}
                        </TabList>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>

                        <TabList centered indicatorColor="secondary" 
                            onChange={(_, nti) => setTabIndex(nti)}
                            style={{
                                backgroundColor: 'black',
                                borderRadius: 5,
                                color: 'white',
                                fontWeight: 'bold',
                                fontFamily: 'Druk Wide Heavy'
                            }}
                            textColor="inherit"
                            orientation="vertical"
                        >
                            <Tab label={`Creations (${drops.length})`} value="0" style={{
                                fontFamily: 'Druk Wide Heavy'
                            }}></Tab>
                            <Tab label={`Users (${minters.length})`} value="1" style={{
                                fontFamily: 'Druk Wide Heavy'
                            }}></Tab>
                            {/* <Tab label={`NFTs (${nfts.length})`} value="2" style={{
                                fontFamily: 'Druk Wide Heavy'
                            }}></Tab> */}
                        </TabList>
                    </Box>
                        
                    <TabPanel value="0">
                       <Box style={{marginBottom: 20}}>
                           <Typography variant="body1">Showing {drops.length} creations found.</Typography>
                        </Box>
                        <Box>
                            <Grid container spacing={3}>
                                { drops.map((drop, i)=> {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} key={`dropcard-${i}`}>
                                            <DropCard 
                                                drop={drop}
                                                key={drop.id}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </TabPanel>

                    <TabPanel value="1">
                        <Box style={{marginBottom: 10}}>
                           <Typography variant="body1">Showing {minters.length} users found.</Typography>
                        </Box>
                        <Box style={{
                            textAlign: 'left',
                            color: 'black'
                        }}>
                            <Grid container spacing={2}>
                                { minters.map((minter, i)=> {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} key={`mintercard-${i}`}>
                                            <UserCard
                                                user={minter}
                                                key={minter.id}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </TabPanel>

                    <TabPanel value="2">
                        <Box style={{marginBottom: 10}}>
                           <Typography variant="body1">Showing {nfts.length} NFTs found.</Typography>
                        </Box>
                        <Box>
                            <Grid container spacing={3}>
                                { nfts.map((nft, i)=> {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} key={`nftcard-${i}`}>
                                            <NftCard 
                                                nft={nft}
                                                key={nft.id}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>

            
        </Container>


        
    );
}