import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, Box, Grid, IconButton, Link } from '@mui/material';
import { FavoriteBorderOutlined, Gradient, Share } from '@mui/icons-material';
import { Drop, NFT, User } from '../../types/api/types';
import { convertS3 } from '../../utils/transformers/s3uri';
import { brandFonts } from '../../utils/constants/colors';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    
  },
  mintersOfInterestContainer: {
    marginBottom: '0.75em'
    },
});

interface UserCardProps {
  user : User
};

export default function UserCard(props : UserCardProps) {
  const classes = useStyles();
    
  return (
      <Box>
        <Box style={{textAlign: 'center', alignItems: 'center', alignContent: 'center'}}>
          <Link href={`/profiles/${props.user.id}`} underline="hover" 
           
            >
              <Avatar alt={props.user.name} src={props.user.profileImageURI || "https://app.onmomentous.io/newicon.png"}
                  style={{
                      width: 170,
                      height: 170,
                      backgroundColor: 'black',
                      margin: '0 auto'
                  }}
                  sizes=''
              />
              </Link>
        </Box>
        <Box style={{textAlign: 'center', marginTop: 5}}>
          <Link
                color='inherit'
                variant="body1"
                component="a"
                href={`/profiles/${props.user.id}`}
                style={{
                  color: 'black',
                  fontFamily: 'DM Sans',
                  fontWeight: 700,
                  letterSpacing: .7,
                  fontSize:12
                }}
                underline="hover">@{props.user.name}</Link><br/>
            <Box>
              <Typography variant="caption" style={{fontSize: 11}}>{props.user.drops.length} Creations</Typography>
            </Box>
            <Box style={{marginTop: '-.3em'}}>
              <Typography variant="caption" style={{fontSize: 11}}>{props.user.claims.length} Collected</Typography>
            </Box>
        </Box>
      </Box>
  
  );
}