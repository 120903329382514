import { Alert, Avatar, Box, Button, CircularProgress, Container, FormHelperText, Grid, MenuItem, Modal, Pagination, Select, SelectChangeEvent, Stack, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { dropService } from '../../clients/backend/drops';
import DropCard from '../../components/drops/card';
import { useAppSelector } from '../../hooks';
import { Drop } from '../../types/api/types';
import { brandColors, brandFonts, gradients } from '../../utils/constants/colors';
import {Helmet} from "react-helmet";


const useStyles = makeStyles({
   
});

export default function DropIndex() {
    const user = useAppSelector( (state) => state.counter.user);
    let classes = useStyles();

    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [pageErrors, setPageErrors] = useState<string[]>([]);
    const [pageLimit, setPageLimit] = useState<number>(20);
    const [pageOffset, setPageOffset] = useState<number>(1);

    const [drops, setDrops] = useState<Drop[]>([]);
    const [totalSearchDropCount, setTotalSearchDropCount] = useState<number | undefined>(undefined);

    const loadDrops = async () => {
        setLoadingData(true);
        setPageErrors([]);

        dropService.search({
            offset: Math.floor((pageOffset - 1 )*pageLimit),
            limit: pageLimit
        }).then( (res) => {
            setDrops(res.drops);
            setTotalSearchDropCount(res.count);
        }).catch( (error) => {
            setPageErrors(["Unable to load drop data. Please refresh the page and try again."]);
        }).finally( () => {
            setLoadingData(false);
        })
    };

    useEffect( () => {
        loadDrops();
    }, []);

    useEffect( () => {
        if(drops.length != 0)
            loadDrops();
    }, [pageLimit, pageOffset])

    
    return (
        <Container>
            <Helmet>
                <title>Momentous - beta :: Drops</title>
                <meta property="og:title" content={`Momentous NFT Drops`} />
            </Helmet>
            {pageErrors.length > 0 && (
                pageErrors.map( (e) => (
                    <Alert severity="error" style={{marginTop: 20}}>{e}</Alert>
                ))
            )}
        
            <Modal open={loadingData}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'rgba(0,0,0,0.8)',
                    border: '2px solid #000',
                    boxShadow: 24,
                    color: 'white',
                    p: 4,
                    textAlign: 'center',
                    alignItems: 'center'
                    }}>
                        <Typography variant="body1">Refreshing Data</Typography>
                        <br/>
                        <CircularProgress />
                </Box>
            </Modal>


            <Box style={{display: 'flex', flexDirection: 'column', marginBottom: 30}}>
                <Box style={{flex: 1}}>
                    <Typography style={{
                        fontFamily: brandFonts.DW_Medium,
                        textAlign: 'left',
                        marginTop: '1em',
                        marginBottom: 10,
                        textTransform: 'uppercase'
                    }} variant="h3" component="h1">
                        Creations
                    </Typography>
                </Box>
                <Box style={{flex: 1}}>
                    <Box>
                        <Pagination count={Math.ceil((totalSearchDropCount || 1) / pageLimit)} variant="outlined" 
                            page={pageOffset}
                            onChange={(event : React.ChangeEvent<unknown>, value: number) => {
                                setPageOffset(value);
                            }}
                        />
                    </Box>
                </Box>
            </Box>

            <Box>
                <Grid container spacing={3}>
                    
                    { drops.map((drop, i)=> {
                        return (
                            <Grid item xs={6} sm={4} md={3} key={`dropcard-${i}`}>
                                <DropCard 
                                    drop={drop}
                                    key={drop.id}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Container>


        
    );
}