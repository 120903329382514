import { ContentCopy, Facebook, Twitter } from '@mui/icons-material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { Alert, Avatar, Box, Button, Chip, CircularProgress, Container, Grid, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { userService } from '../../clients/backend/users';
import DropCard from '../../components/drops/card';
import NftCard from '../../components/nfts/card';
import { useAppSelector } from '../../hooks';
import { User } from '../../types/api/types';
import { brandColors, brandFonts, gradients } from '../../utils/constants/colors';
import {Helmet} from "react-helmet";


const profileImageOffset = 40;

const useStyles = makeStyles({
    headerImg: {
        backgroundColor: 'black',
        display: 'block',
        height: 200,
        marginTop: 20,
        borderRadius: 10,
        overflow: 'hidden',
        wordWrap: 'break-word'
    },
    wrapper: {
        position: 'relative',
        marginBottom: profileImageOffset*1.5
    },
    headerUserName: {
        color: 'black',
        fontFamily: 'Druk Wide Heavy Italic',
        WebkitTextStrokeWidth: 'thin',
        WebkitTextStrokeColor: brandColors.limeGreen,
        display: 'inline',
        marginRight: 10
    },
    profileImage: {
        width: 200,
        height: 200,
        //position: 'absolute',
        bottom: -profileImageOffset,
        //left: 0,
        border: 'solid 4px white',
        backgroundColor: 'black',
        color: 'white',
        textAlign: 'center',
        alignSelf: 'center',
        margin: '0 auto'
    },
    followButton: {
        width: '100%',
        
        borderRadius: 30,
        borderColor: 'black',
        color: 'black',
        textTransform: 'none'
    },
    followingButton: {
        width: '100%',
        background: brandColors.limeGreen,
        color: 'black',
        borderRadius: 30,
        borderColor: brandColors.limeGreen,
        textTransform: 'none',
        fontWeight: 'bold'
    }
});

type ProfileShowParams = {
    userId : string;
}

export default function ProfileShow() {
    const user = useAppSelector( (state) => state.counter.user);
    let { userId } = useParams<ProfileShowParams>();
    
    const [profile, setProfile] = useState<User | undefined>(undefined);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [pageErrors, setPageErrors] = useState<string[]>([]);

    const [isProcessingFollow, setIsProcessingFollow] = useState<boolean>(false);
    const [isFollowing, setIsFollowing] = useState<boolean>(false);
    const [tabIndex, setTabIndex] = useState<string>("0");
    let classes = useStyles();


    const loadProfile = async () => {
        setLoadingData(true);

        userService.getByID(userId).then( (res) => {
            setProfile(res);

            user?.follows.forEach( (profileFollow) => {
                if(profileFollow.userID === res.id) {
                    setIsFollowing(true);
                }
            })

        }).catch( (error) => {
            setPageErrors(["Unable to load profile data. Please refresh the page and try again."]);
        }).finally( () => {
            setLoadingData(false);
        });
    };

    const toggleFollow = async () => {
        if(!user) {
            alert("You must be logged in to follow a creator on Momentous.");
            return;
        }
        if(!user || !profile) return;

        if(user.id === profile.id) return;

        setIsProcessingFollow(true);

        if(isFollowing) {
            userService.unfollow(profile.id).then( (res) => {
                if(res) {
                    setIsFollowing(false);
                }
            }).catch( (error) => {
                setPageErrors(["Unable to follow profile. Please refresh the page and try again."]);
            }).finally( () => {
                setIsProcessingFollow(false);
            });
        } else {
            userService.follow(profile.id).then( (res) => {
                if(res) {
                    setIsFollowing(true);
                }
            }).catch( (error) => {
                setPageErrors(["Unable to follow profile. Please refresh the page and try again."]);
            }).finally( () => {
                setIsProcessingFollow(false);
            });
        }
    }

    useEffect( () => {
        //loadProfile();
    }, []);

    useEffect( () => {
        loadProfile();
    }, [userId, user]);

    let publicAddress = profile?.authDetails?.publicAddress || "";

    
    return (
    <Box>
        <Helmet>
            <title>Momentous - Beta :: Profile</title>
            <meta name="description" content="Momentous - NFTs for everyone" />
            <meta name="og:title" content="Momentous - beta :: Profile" />
            <meta name="og:description" content="Momentous - NFTs for everyone. Mint, Share, and Claim your favorite NFTs instantly with Momentous!" />
            <meta name="og:image" content={`${profile?.profileImageURI}`} />
        </Helmet>
        <Box style={{
            backgroundColor: brandColors.limeGreen,
            height: 200, 
            marginBottom: profileImageOffset
        }}>
            <Container style={{position: 'relative', height: '100%'}}>
                <Grid container>
                    <Grid item xs={12} sm={4} md={3}>
                        <Box>
                            <Avatar className={classes.profileImage} src={profile?.profileImageURI || "https://app.onmomentous.io/newicon.png"} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}></Grid>
                </Grid>
            </Container>
        </Box>
        <Container>
            <Grid container style={{marginTop: 10}}>
                <Grid item xs={12} sm={4} md={3} style={{textAlign: 'center'}}>
                    <Box style={{textAlign: 'center', marginTop: 20}}>
                        <Typography variant="h4" style={{textAlign: 'center', fontSize: 24, fontWeight: 'bold', letterSpacing: 0.5}}>{profile?.name}</Typography>
                    </Box>
                    <Box style={{
                        marginTop: 45,
                        marginBottom: 15
                    }}>
                        <Box style={{
                            border: 'solid 1px black',
                            borderRadius: 40,
                            paddingTop: 5,
                            paddingBottom: 3,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}> 
                            <Typography style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: 30,
                                padding: 5,
                                paddingLeft: 10,
                                paddingRight: 10,
                                fontSize: 12,
                                flex: 1,
                                marginLeft: 4,justifyContent: 'center'
                            }}>{profile?.serialNumber}</Typography>
                            <Typography variant="body1" style={{fontFamily: 'Courier', paddingTop: 1, fontSize: 12, flex: 3,justifyContent: 'center'}}>
                                {publicAddress.toLowerCase().slice(0,5)}...{publicAddress.toLowerCase().slice(36)} <Tooltip title="Copy Address"><IconButton ><ContentCopy style={{fontSize: 10}} onClick={() => {
                                    navigator.clipboard.writeText(publicAddress.toLowerCase())
                                }} />
                                </IconButton>
                                </Tooltip>
                            </Typography>
                        </Box>
                    </Box>
                    <Box style={{marginTop: 30}}>
                        <Box style={{display: 'flex', flexDirection: 'row'}}>
                            <Box style={{flex: 1}}>
                                <Typography style={{fontWeight: 'bold'}}>{profile?.follows.length}</Typography>
                                <Typography style={{fontSize: 12}}>Following</Typography>
                            </Box>
                            <Box style={{flex: 1}}>
                                <Typography style={{fontWeight: 'bold'}}>{profile?.followers.length}</Typography>
                                <Typography style={{fontSize: 12}}>Followers</Typography>
                            </Box>
                            {/* {profile?.id !== user?.id && (
                            <Box style={{flex: 1}}>
                                <LoadingButton loading={isProcessingFollow} disabled={isProcessingFollow} variant="outlined" color="primary" className={isFollowing? classes.followingButton : classes.followButton} onClick={() => {
                                    toggleFollow()
                                }}>
                                    {isFollowing ? 'Following' : 'Follow'}
                                </LoadingButton>
                            </Box>
                            )} */}
                        </Box>
                        <Box style={{
                            border: 'solid 1.0px #DDD',
                            marginTop: 30
                        }}>
                            
                        </Box>
                        
                        <Box style={{marginTop: 10}}>
                            <Box style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'left', textAlign: 'left'}}>
                                <Box style={{flex: 1}}>
                                    <IconButton size="small" style={{color: 'black'}} href={`https://twitter.com/intent/tweet?text=Check out ${profile?.name}'s profile on @OnMomentous!&url=https://app.onmomentous.io/profiles/${profile?.id}&hashtags=nfts,drops,momentous`} target="_blank">
                                        <Twitter />
                                    </IconButton>
                                    {/* <IconButton size="small" style={{color: 'black'}}
                                    href={`https://www.facebook.com/sharer/sharer.php?u=https://app.onmomentous.io/profiles/${profile?.id}`}
                                    target="_blank"
                                    >
                                        <Facebook />
                                    </IconButton> */}
                                </Box>
                                
                            </Box>
                            {/* { profile?.id === user?.id && (
                                <Box style={{
                                    textAlign: 'left',
                                    marginTop: 10,
                                    
                                }}>
                                    <Button variant="outlined" style={{
                                        color: '#000',
                                        borderColor: 'black',
                                        borderRadius: 30,
                                        minWidth: '45%',
                                        textTransform: 'none'
                                    }}>Log Out</Button>
                                </Box>
                            )} */}
                            
                        </Box>

                        

                        
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    {pageErrors.length > 0 && (
                        pageErrors.map( (e) => (
                            <Alert severity="error" style={{marginTop: 20}}>{e}</Alert>
                        ))
                    )}
                    
                    {profile && (
                    <Box>
                        

                        <TabContext value={tabIndex}>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }}}>

                                <TabList indicatorColor="primary" 
                                    onChange={(_, nti) => setTabIndex(nti)}
                                    style={{
                                        
                                        margin: 20,
                                        borderRadius: 5,
                                        color: 'black',
                                        fontWeight: 'bold',
                                    }}
                                    textColor="inherit"
                                >
                                    <Tab label={`Created ${profile.drops.length}`} value="0" style={{
                                        fontWeight: 'bold',
                                        textTransform: 'none'
                                    }}></Tab>
                                    <Tab label={`Collected ${profile.ownedNFTs.length}`} value="1" style={{
                                        fontWeight: 'bold',
                                        textTransform: 'none'
                                    }}></Tab>
                                    {/* <Tab label="For Sale" value="2" style={{
                                        fontWeight: 'bold',
                                        textTransform: 'none'
                                    }}></Tab> */}
                                </TabList>
                            </Box>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>

                                <TabList indicatorColor="primary" 
                                    onChange={(_, nti) => setTabIndex(nti)}
                                    style={{
                                        backgroundColor: 'white',
                                        margin: 20,
                                        borderRadius: 5,
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontFamily: 'Druk Wide Heavy'
                                    }}
                                    textColor="inherit"
                                    
                                >
                                     <Tab label={`Created ${profile.drops.length}`} value="0" style={{
                                        fontWeight: 'bold',
                                        textTransform: 'none'
                                    }}></Tab>
                                    <Tab label={`Collected ${profile.ownedNFTs.length}`} value="1" style={{
                                        fontWeight: 'bold',
                                        textTransform: 'none'
                                    }}></Tab>
                                    {/* <Tab label="For Sale" value="2" style={{
                                        fontWeight: 'bold',
                                        textTransform: 'none'
                                    }}></Tab> */}
                                </TabList>
                            </Box>
                            {loadingData && (
                                <Box style={{marginTop: 30}}>
                                    <Typography variant="body1">Refreshing Profile Data</Typography>
                                    <CircularProgress />
                                </Box>
                            )}
                            <TabPanel value="0">
                                <Grid container spacing={3}>
                                    {profile.drops.slice(0,50).map( (drop, i) => (
                                        <Grid item xs={6} sm={4} md={4} key={`dropcard-${i}`}>
                                            <DropCard 
                                                drop={drop}
                                                key={drop.id}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </TabPanel>

                            <TabPanel value="1">
                                {profile.ownedNFTs.length == 0 && (
                                    <Typography variant="body1"  style={{textAlign: 'left'}}>No NFTs have been collected.</Typography>
                                )}
                                <Grid container spacing={3}>
                                    {profile.ownedNFTs.slice(0,50).map( (_nft, i) => (
                                        <Grid item xs={6} sm={4} md={4} key={`nftcard-${i}`}>
                                            <NftCard 
                                                nft={_nft}
                                                key={_nft.id}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </TabPanel>

                            <TabPanel value="2">
                                <Typography variant="body1" style={{textAlign: 'left'}}>Marketplace functionality is coming soon to Momentous!</Typography>
                            </TabPanel>
                        </TabContext>

                    </Box>
                    )}
                </Grid>
            </Grid>
            
            
            
            
        </Container>


    </Box>
    );
}