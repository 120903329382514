import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { User } from '../types/api/types'

// Define a type for the slice state
interface CounterState {
  value: number
  user: User | undefined,
  redirectString: String | undefined
}

// Define the initial state using that type
const initialState: CounterState = {
    value: 0,
    user: undefined,
    redirectString: undefined
}

export const counterSlice = createSlice({
  name: 'counter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload
    },
    loadUserDetails: (state, action: PayloadAction<User | undefined>) => {
        state.user = action.payload;
    },
    logoutUser: (state) => {
      state.user = undefined;
    },
    setRedirectLocation: (state, action: PayloadAction<String | undefined>) => {
      state.redirectString = action.payload;
    }
  },
})

export const { loadUserDetails, increment, decrement, incrementByAmount, logoutUser, setRedirectLocation } = counterSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.counter.value

export default counterSlice.reducer