import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, Box, Icon, IconButton, Link } from '@mui/material';
import { Facebook, FavoriteBorderOutlined, Gradient, PlayArrow, Share, Twitter } from '@mui/icons-material';
import { Drop } from '../../types/api/types';
import { convertS3 } from '../../utils/transformers/s3uri';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    boxShadow: 'none'
  },
  media: {
    borderRadius: 10
  },
});

interface DropCardProps {
  drop : Drop
};

export default function DropCard(props : DropCardProps) {
  const classes = useStyles();

  let dropLinkRoute = `/drops/${props.drop.id}`;

  return (
    <Card className={classes.root}>
      <CardActionArea href={dropLinkRoute}>
        <CardMedia
          className={classes.media}
          component="img"
          image={props.drop.nfts.length > 0 ? convertS3(props.drop.nfts[0].mediaEntity?.previewS3URI): 'https://momentous-brand-assets.s3.us-east-2.amazonaws.com/Momentous-Icon-White-Transparent%402x.png'}
          title={`${props.drop.name}`}
          style={{
            backgroundColor: 'black',
            marginBottom: 0
          }}
          
        />
        <CardContent style={{
          borderWidth: 0,
          paddingTop: 3,
          paddingLeft: 0,
          alignContent: 'left',
          textAlign: 'left'
        }}>
          <Link
            variant="caption"
            component="a"
            display="inline"
            style={{
              textAlign: 'left'
            }}
            color="textSecondary"
            href={`/profiles/${props.drop.user.id}`}
            underline="hover">
              @{props.drop.user.name}
          </Link>
            
          {props.drop.nfts && props.drop.nfts.length > 0 && (
          <>
            <Typography align="left" style={{
              fontWeight: 'bold',
              fontSize: 12,
              letterSpacing: 0.25
            }}>{props.drop.name}</Typography>
            <Typography align="left" color="textSecondary" style={{
              fontSize: 12,
              letterSpacing: 0.25
            }}>{props.drop.nfts[0].numberOfEditions} {props.drop.nfts[0].numberOfEditions === 1 ? 'Edition' : 'Editions'}</Typography>
          </>
          )}
          
        </CardContent>
      </CardActionArea>
    </Card>
  );
}