import { httpsClient } from "./client"
interface AuthLoginParams {
    didt: string,
    provider: string;
    providerID: string;
    name: string;
    email: string;
    handle: string;
    pictureUrl: string;
}
const login = async (params : AuthLoginParams) : Promise<boolean> =>  {
    return new Promise<boolean>( (resolve, reject) => {  
        httpsClient.client.post('/auth/login', {
            didt: params.didt,
            provider: params.provider,
            providerID: params.providerID,
            name: params.name,
            email: params.email,
            handle: params.handle,
            pictureUrl: params.pictureUrl
        }).then ( (result) => {
            console.log(`Got login result`, result);
            resolve(true);
        }).catch( (error) => {
            console.log(error);
            reject(error);
        }).finally( () => {
            console.log("I am all done");
        })
    })
}

const corsTest = async () : Promise<boolean> =>  {
    return new Promise<boolean>( (resolve, reject) => {  
        httpsClient.client.get('/hello').then ( (result) => {
            console.log(`Got login result`, result);
            resolve(true);
        }).catch( (error) => {
            console.log(error);
            reject(error);
        })
    })
}

export const authService = {
    login,
    corsTest
}