import { httpsClient } from "./client";
import { Drop, DropClaimNFT, NFT } from "../../types/api/types";

interface SetDropClaimResult {
    data: {
        drop: Drop;
    };
};

interface ClaimClaimResult {
    data: {
        nft: NFT;
        claimedNFT: DropClaimNFT;
    };
}

interface DropSearchRequest {
    query?: string;
    offset?: number;
    limit?: number;
}

interface DropSearchOutput {
    drops : Drop[];
    count : number;
}

const search = async(searchRequest : DropSearchRequest) : Promise<DropSearchOutput> => {
    return new Promise<DropSearchOutput>( (resolve, reject) => {
        httpsClient.client.get(`/drops/search`, {
            params: searchRequest
        }).then( (result) => {
            resolve(result.data);
        }).catch( (error) => {
            reject( `Error searching Drops ${error}`);
        });
    });
};

const getDropByID = async(id : string) : Promise<Drop> => {
    return new Promise<Drop>( (resolve, reject) => {
        httpsClient.client.get(`/drops/${id}`).then( (result) => {
            resolve(result.data.drop);
        }).catch( (error) => {
            reject( `Error fetching Drop ${error}`);
        });
    });
};

const claim = async (dropID : string) : Promise<{claim: DropClaimNFT, nft: NFT}> => {
    if (dropID === undefined || dropID.length === 0) return new Promise<{claim: DropClaimNFT, nft: NFT}>( (_, reject) => {
        reject('Invalid Drop ID provided');
    });

    return new Promise<{claim: DropClaimNFT, nft: NFT}>( (resolve, reject) => {
        httpsClient.client.post(`/drops/${dropID}/claim`).then( (result : ClaimClaimResult) => {
            resolve({
                claim: result.data.claimedNFT,
                nft: result.data.nft
            });
        }).catch( (error) => {
            console.log(`Error claiming claim for drop ${error}`);
            reject( `Error claiming claim for drop`);
        });

    });
}

const setDropClaim = async (dropID : string | undefined, claimable : boolean) : Promise<Drop> => {
    if (dropID === undefined || dropID.length === 0) return new Promise<Drop>( (_, reject) => {
        reject('Invalid Drop ID provided');
    });
    
    return new Promise<Drop>( (resolve, reject) => {
        httpsClient.client.post(`/drops/${dropID}/setClaim`, {claimable: claimable}).then( (result : SetDropClaimResult) => {
            resolve(result.data.drop)
        }).catch( (error) => {
            reject( `Error setting claim for drop` );
        })
    });
};

export const dropService = {
    setDropClaim,
    claim,
    getDropByID,
    search
};