import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { AppBar, Button, Container, Toolbar, Typography, Link as Link2, IconButton, Box, Stack, Grid, Menu, MenuItem} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import makeStyles from '@mui/styles/makeStyles';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useHistory,
  useLocation
} from 'react-router-dom';
import HomeIndex from './pages/home';
import ProfileShow from './pages/profiles/show';
import LoginIndex from './pages/login';
import DropShow from './pages/drops/show';
import DropIndex from './pages/drops';
import LoginCallback from './pages/login/callback';
import { userService } from './clients/backend/users';
import { User } from './types/api/types';
import { loadUserDetails } from './slices/user';
import { useAppDispatch, useAppSelector } from './hooks';
import NftDropShow from './pages/drops/nft';
import { Search, SearchRounded } from '@mui/icons-material';
import { JWT_KEY } from './constants/auth';
import { magic } from './clients/magic/client';
import { httpsClient } from './clients/backend/client';
import LogoutIndex from './pages/logout';
import SearchIndex from './pages/search';
import {Helmet} from "react-helmet";
import MintIndex from './pages/mint';
import RedemptionShow from './pages/redemptions/show';
import { brandColors, brandFonts } from './utils/constants/colors';


const brand = {
  color: {
    limeGreen: '#D2F43B',
    blue: '#3BD2F4',
    blurple: '#5D3BF4',
    orange: '#F45D3B',
    black: '#000000',
    white: '#FFFFFF'
  }
};

const useStyles = makeStyles({
  footerLinks: {
    color: 'black',
    fontFamily: brandFonts.DW_Medium,
    fontWeight: 900,
    textTransform: 'uppercase',
    letterSpacing: .5,
    fontSize: 15,
    marginBottom: 15
  },
  disclosureLinks: {
    color: 'black',
    fontFamily: brandFonts.DW_Medium,
    textTransform: 'uppercase',
    fontSize: 12,
  },
  inlineDisclosureLinks: {
    color: 'black',
    fontFamily: brandFonts.DW_Medium,
    textTransform: 'uppercase',
    fontSize: 12,
    display: 'inline'
  }
});

function App() {
  const user = useAppSelector( (state) => state.counter.user);
  const dispatch = useAppDispatch();
  const [chosenPath, setChosenPath] = useState<string>("");
  const classes = useStyles();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  useEffect( () => {
    console.log("use effect in App()")
    userService.getDetails().then( (user : User) => {
      dispatch(loadUserDetails(user));
    }).catch( (error) => {
      //alert("User is logged out");
      dispatch(loadUserDetails(undefined));
    });

    magic.getClient().user.isLoggedIn().then( (res) => {
      if(res === false) {
        dispatch(loadUserDetails(undefined));
      }
    }).catch( (error) => {

    })


  }, []);


  const pages = ['Products', 'Pricing', 'Blog'];
  let location = useLocation();
  useEffect( () => {
    if(location.pathname === undefined) return;
    if(location.pathname.toLowerCase() === "/") {
      setChosenPath("home");
    }
    if(location.pathname.toLowerCase().includes("search")) {
      setChosenPath("search");
    }
    if(location.pathname.toLowerCase().includes("drops")) {
      setChosenPath("drops");
    }
    if(location.pathname.toLowerCase().includes("mint")) {
      setChosenPath("mint");
    }
    if(location.pathname.toLowerCase().includes("login")) {
      setChosenPath("login");
    }
    if(location.pathname.toLowerCase().includes("profiles")) {
      setChosenPath("profiles");
    }
    console.log(`Location: ${location}`, location)
  }, [location])
  


  return (
    
      <div className="App">
        <Helmet>
          <title>Momentous - Beta</title>
          <meta name="description" content="Momentous - NFTs for everyone" />
          <meta name="og:title" content="Momentous - beta" />
          <meta name="og:description" content="Momentous - NFTs for everyone. Mint, Share, and Claim your favorite NFTs instantly with Momentous!" />
        </Helmet>
        <AppBar position="static" style={{
          backgroundColor: brand.color.black
        }}>
          <Toolbar>
            <Link2 href="/" underline='none' style={{
              color: brand.color.white,
              fontFamily: 'Druk Wide Heavy',
              flexGrow: 1,
              textAlign: 'left'
            }}>momentous
              <span style={{color: brandColors.limeGreen, fontSize: '0.5em', marginLeft: 3, position: 'relative', top: -10, letterSpacing: 1, fontFamily: brandFonts.DW_Medium}}>BETA</span>
            </Link2>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'right' }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="primary"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                
                  <MenuItem key="explor-mi" onClick={handleCloseNavMenu} style={{backgroundColor: 'black'}}>
                    <Link2 href="/" underline='none' style={{
                        color: chosenPath === "home" ? brand.color.limeGreen : brand.color.white,
                        fontFamily: brandFonts.DW_Medium,
                        marginRight: 10,
                        textTransform: 'uppercase',
                        letterSpacing: 0.6,
                        fontSize: '0.8em',
                      }}>Discover</Link2>
                    </MenuItem>
                    
                    {/* <MenuItem key="drops-mi" onClick={handleCloseNavMenu} style={{backgroundColor: 'black'}}>

                    <Link2 href="/drops" underline='none' style={{
                      color: chosenPath === "drops" ? brand.color.limeGreen : brand.color.white,
                      fontFamily: brandFonts.DW_Medium,
                      marginRight: 10,
                      textTransform: 'uppercase',
                      letterSpacing: 0.6,
                      fontSize: '0.8em',
                    }}>Drops</Link2>
                  </MenuItem> */}


                  {/* <MenuItem key="mint-mi" onClick={handleCloseNavMenu}  style={{backgroundColor: 'black'}}>
                    <Link2 href="/mint" underline='none' style={{
                      color: chosenPath === "mint" ? brand.color.limeGreen : brand.color.white,
                      fontFamily: brandFonts.DW_Medium,
                      marginRight: 10,
                      textTransform: 'uppercase',
                      letterSpacing: 0.6,
                      fontSize: '0.8em',
                    }}>Mint</Link2>
                  </MenuItem> */}

                  <MenuItem key="search-mi" onClick={handleCloseNavMenu}  style={{backgroundColor: 'black'}}>
                    <Link2 href="/search" underline='none' style={{
                      color: chosenPath === "search" ? brand.color.limeGreen : brand.color.white,
                      fontFamily: brandFonts.DW_Medium,
                      marginRight: 10,
                      textTransform: 'uppercase',
                      letterSpacing: 0.6,
                      fontSize: '0.8em',
                    }}>Search</Link2>
                  </MenuItem>

                  { user == undefined && (
                  <MenuItem key="login-mi" onClick={handleCloseNavMenu}  style={{backgroundColor: 'black'}}>
                    <Link2 href="https://www.onmomentous.io/#Early-Access" target="_blank" underline='none' style={{
                      color: chosenPath === "login" ? brand.color.limeGreen : brand.color.white,
                      fontFamily: brandFonts.DW_Medium,
                      marginRight: 10,
                      textTransform: 'uppercase',
                      letterSpacing: 0.6,
                      fontSize: '0.8em',
                    }}>Join The Beta</Link2>
                  </MenuItem>
                  )}

                  { user != undefined && (
                    <Box>
                  <MenuItem key="profile-mi" onClick={handleCloseNavMenu}  style={{backgroundColor: 'black'}}>
                    <Link2 href={`/profiles/${user.id}`} underline='none' style={{
                      color: chosenPath === "profiles" ? brand.color.limeGreen : brand.color.white,
                      fontFamily: brandFonts.DW_Medium,
                      marginRight: 10,
                      textTransform: 'uppercase',
                      letterSpacing: 0.6,
                      fontSize: '0.8em',
                    }}>Profile</Link2>
                  </MenuItem>

                  <MenuItem key="logout-mi" onClick={handleCloseNavMenu}  style={{backgroundColor: 'black'}}>
                    <Link2 href="/logout" underline='none' style={{
                      color: chosenPath === "logout" ? brand.color.limeGreen : brand.color.white,
                      fontFamily: brandFonts.DW_Medium,
                      marginRight: 10,
                      textTransform: 'uppercase',
                      letterSpacing: 0.6,
                      fontSize: '0.8em',
                    }}>[ Logout ]</Link2>
                  </MenuItem>
                  </Box>
                  )}
                
              </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'right' }}>
            <Box style={{justifyContent: 'center'}}>
              <Link2 href="/" underline='none' style={{
                color: chosenPath === "home" ? brand.color.limeGreen : brand.color.white,
                fontFamily: brandFonts.DW_Medium,
                marginRight: 25,
                textTransform: 'uppercase',
                letterSpacing: 0.6,
                fontSize: '0.8em',
              }}>Discover</Link2>
              
              {/* <Link2 href="/drops" underline='none' style={{
                color: chosenPath === "drops" ? brand.color.limeGreen : brand.color.white,
                fontFamily: brandFonts.DW_Medium,
                marginRight: 25,
                textTransform: 'uppercase',
                letterSpacing: 0.6,
                fontSize: '0.8em'
              }}>Drops</Link2> */}

              {/* <Link2 href="/mint" underline='none' style={{
                color: chosenPath === "mint" ? brand.color.limeGreen : brand.color.white,
                fontFamily: brandFonts.DW_Medium,
                marginRight: 25,
                textTransform: 'uppercase',
                letterSpacing: 0.6,
                fontSize: '0.8em'
              }}>Mint</Link2> */}

              { user == undefined && (
                <Link2 href="https://www.onmomentous.io/#Early-Access" target="_blank" underline='none' style={{
                  color: chosenPath === "login" ? brand.color.limeGreen : brand.color.white,
                  fontFamily: brandFonts.DW_Medium,
                  marginRight: 25,
                  textTransform: 'uppercase',
                  letterSpacing: 0.6,
                  fontSize: '0.8em'
                }}>Join The Beta</Link2>
              )}

              <Link2 href="/search" underline='none' style={{
                  color: chosenPath === "search" ? brand.color.limeGreen : brand.color.white,
                  fontFamily: brandFonts.DW_Medium,
                  marginRight: 25,
                  textTransform: 'uppercase',
                  letterSpacing: 0.6,
                  fontSize: '0.8em',
                }}><SearchRounded style={{
                  position: 'relative',
                  bottom: -8
                }}/></Link2>

              { user != undefined && (
                <>
                <Link2 href={`/profiles/${user.id}`} underline='none' style={{
                  color: chosenPath === "profiles" ? brand.color.limeGreen : brand.color.white,
                  fontFamily: brandFonts.DW_Medium,
                  marginRight: 0,
                  textTransform: 'uppercase',
                  letterSpacing: 0.6,
                  fontSize: '0.8em',
                }}>Profile</Link2>
                &nbsp;
                <Link2 href="/logout" underline='none' style={{
                  color: brand.color.white,
                  fontFamily: brandFonts.DW_Medium,
                  marginRight: 25,
                  textTransform: 'uppercase',
                  letterSpacing: 0.6,
                  fontSize: '0.6em',
                }}>[ Logout ]</Link2>
                </>
              )}
            </Box>
          </Box>
            
          </Toolbar>
        </AppBar>
        
        
        
        <Box style={{
          minHeight: '80vh'
        }}>
          <Switch>
            <Route exact path="/">
              <HomeIndex />
            </Route>
            <Route exact path="/login">
              <LoginIndex />
            </Route>
            <Route exact path="/logout">
              <LogoutIndex />
            </Route>
            <Route exact path="/callback">
              <LoginCallback />
            </Route>
            <Route exact path="/mint">
              <MintIndex />
            </Route>
            <Route path="/drops">
              <DropRouter />
            </Route>
            <Route path="/profiles">
              <ProfileRouter />
            </Route>
            <Route path="/redemptions">
              <RedemptionRouter />
            </Route>
            <Route path="/search">
              <SearchIndex />
            </Route>
          </Switch>
          
        </Box>




        <footer style={{
          position: 'relative',
          bottom: 0,
          marginTop: 20,
          width: '100%',
          backgroundColor: brandColors.limeGreen
        }}>
          <Container style={{
            //backgroundColor: 'black',
            //height: '100%',
            //
            paddingTop: 40,
            textAlign: 'left',
            
            paddingBottom: 40,
          }}>
            <Box>
              <Typography style={{
                fontFamily: brandFonts.DWH
              }}
              variant="h5"
              >momentous</Typography>
            </Box>
            <Box style={{marginTop: 50, marginBottom: 70}}>
              <Grid container spacing={3} >
                <Grid item xs={12} sm={4}>
                  <Stack style={{flex: 1}}>
                    <Link2 underline='hover' href="https://www.instagram.com/onmomentous/" target="_blank" variant='subtitle1' className={classes.footerLinks}>Instagram</Link2>
                    <Link2 underline='hover' href="https://twitter.com/OnMomentous" target="_blank" variant='subtitle1' className={classes.footerLinks}>Twitter</Link2>
                    <Link2 underline='hover' href="https://discord.gg/aU66ypYAz8" target="_blank" variant='subtitle1' className={classes.footerLinks}>Discord</Link2>

                    {/* <Link2 underline='hover' href="https://apps.apple.com/us/app/momentous-nfts/id1594705169" target="_blank" variant='subtitle1' className={classes.footerLinks}>iOS App</Link2> */}
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Stack style={{flex: 1}}>
                  <Link2 underline='hover' href="https://www.onmomentous.io/" target="_blank" variant='subtitle1' className={classes.footerLinks}>About</Link2>
                    <Link2 underline='hover' href="mailto:contact@onmomentous.io" target="_blank" variant='subtitle1' className={classes.footerLinks}>Contact</Link2>
                    <Link2 underline='hover' href="https://www.onmomentous.io/faq" target="_blank" variant='subtitle1' className={classes.footerLinks}>FAQ</Link2>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={4}>
                
                </Grid>

              </Grid>
            </Box>
            <Box style={{marginBottom: 20}}>
              <Box style={{display: 'flex', flexDirection: 'row'}}>
                <Box style={{flex: 1}}>
                  <Typography className={classes.disclosureLinks}>© 2023 Momentous Place Inc. All Rights Reserved</Typography>
                </Box>
                <Box style={{flex: 1, justifyContent: 'flex-end', textAlign: 'right'}}>
                  <Link2 underline='hover' href="https://www.onmomentous.io/terms-of-use" target="_blank" variant='subtitle1' className={classes.inlineDisclosureLinks}>Terms of Service, </Link2>
                  <Link2 underline='hover' href="https://www.onmomentous.io/privacy-policy" target="_blank" variant='subtitle1' className={classes.inlineDisclosureLinks}>Privacy Policy, </Link2>
                  <Link2 underline='hover' href="https://www.onmomentous.io/report-offensive-content-users" target="_blank" variant='subtitle1' className={classes.inlineDisclosureLinks}>Report Content</Link2>
                </Box>
              </Box>
            </Box>
            
          </Container>
        </footer>
        
      </div> 
  );
}

const DropRouter = () => {
  let match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/:dropId`} exact>
          <DropShow />
        </Route>
        <Route path={`${match.path}/:dropId/:nftId`} exact>
          <NftDropShow />
        </Route>
        <Route exact path={""}>
          <DropIndex />
        </Route>
      </Switch>
    </div>
  )
}

const ProfileRouter = () => {
  let match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/:userId`}>
          <ProfileShow />
        </Route>
      </Switch>
    </div>
  );
};

const RedemptionRouter = () => {
  let match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/:redemptionId`}>
          <RedemptionShow />
        </Route>
      </Switch>
    </div>
  );
};


export default App;
