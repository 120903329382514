import { httpsClient } from "./client";
import { Drop, NFT } from "../../types/api/types";

export interface CreateNFTData {
    dropCount: number;
    pricingType?: string;
    pricingCurrency?: string;
    pricingValue?: number;
    description: string;
    eventID?: string | undefined;
    rtmID?: string | undefined;
    tags: string[];
    mediaEntityID: string;
    title?: string;
    shouldBeClaimable?: boolean;
};

interface CreateNFTResponseData {
    data: CreateNFTResponse;
};

interface CreateNFTResponse {
    nft: NFT;
    drop: Drop;
}

// Creates an NFT and returns it's result.
const createNFT = async(params : CreateNFTData) : Promise<CreateNFTResponse> => {
    return new Promise<CreateNFTResponse>( (resolve, reject) => {
        httpsClient.client.post("/nft/create", 
            params
        ).then( (result : CreateNFTResponseData) => {
            resolve(result.data);
        }).catch( (error) => {
            reject( `Error Creating NFT ${error}`);
        });
    });
};

const getNFTByID = async(id : string) : Promise<NFT> => {
    return new Promise<NFT>( (resolve, reject) => {
        httpsClient.client.get(`/nft/${id}`).then( (result) => {
            resolve(result.data.nft);
        }).catch( (error) => {
            console.log(`NFT FETCH ERROR ${error}`)
            reject( `Error fetching NFT ${error}`);
        });
    });
};

const search = async () : Promise<NFT[]> => {
    return new Promise<NFT[]>( (resolve, reject) => {
        httpsClient.client.get(`/nft/search`).then( (result) => {
            resolve(result.data.nfts.map( (nft : NFT) => {
                if(nft.mediaEntity) {
                    nft.mediaEntity.signedURI = result.data.signedURIs[nft.id];
                }
                return nft;
            }));
        }).catch( (error) => {
            reject( `Error searching NFTs ${error}`);
        });
    })
}

export const nftService = {
    createNFT,
    getNFTByID,
    search
};