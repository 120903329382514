import { httpsClient } from "./client";
import { Drop, NFT, NFTRedemption } from "../../types/api/types";
import { rejects } from "assert";

const getByKey = async (redemptionKey : string) : Promise<NFTRedemption> => {
    return new Promise<NFTRedemption>( (resolve, reject) => {
        httpsClient.client.get(`/redemptions/${redemptionKey}`).then( (result) => {
            resolve(result.data.redemption)
        }).catch( (error) => {
            reject(`Error getting redemption ${error}`)
        })
    })
};

const redeem = async (redemptionKey : string) : Promise<NFTRedemption> => {
    return new Promise<NFTRedemption>( (resolve, reject) => {
        httpsClient.client.post(`/redemptions/redeem`, {
            redemptionKey: redemptionKey
        }) .then( (result) => {
            resolve(result.data.redemption);
        }).catch((error) => {
            reject(`Error redeeming redemption: ${error}`)
        })
    });
}

export const redemptionService = {
    getByKey,
    redeem
};