import { Box, CircularProgress, Container, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { httpsClient } from "../../clients/backend/client";
import { magic } from "../../clients/magic/client";
import { JWT_KEY } from "../../constants/auth";
import { useAppDispatch } from "../../hooks";
import { logoutUser } from "../../slices/user";
import {Helmet} from "react-helmet";



export default function LogoutIndex() {
    const dispatch = useAppDispatch();
    const [loggingOut, setLoggingOut] = React.useState<boolean>(false);
    const [logOutError, setLogOutError] = React.useState<string | undefined>(undefined);
    const [loggedOut, setLoggedOut] = React.useState<boolean>(false);

    useEffect(() => {
        setLoggingOut(true);
        setLogOutError(undefined);
        setLoggedOut(false);

        magic.getClient().user.logout().then( () => {
            localStorage.removeItem(JWT_KEY);
            httpsClient.client.defaults.headers.common['Authorization'] = "";
            setLoggedOut(true);
            dispatch(logoutUser);
          }).catch( (error) => {
            setLogOutError("An error occurred while we tried to log out your profile. Please refresh the page and try again. If the problem persists, clear browser settings.");
          }).finally( () => {
              setLoggingOut(false);
          })
       
    }, [])

    if(loggedOut) {
        return (
            <Redirect to="/" />
        )
    }

    return (
        <Container>
            <Helmet>
                <title>Momentous - Beta :: Logout</title>
                <meta name="description" content="Momentous - NFTs for everyone" />
                <meta name="og:title" content="Momentous - beta :: Logout" />
                <meta name="og:description" content="Momentous - NFTs for everyone. Mint, Share, and Claim your favorite NFTs instantly with Momentous!" />
            </Helmet>
            <Box>
                {loggingOut && (
                    <>
                        <Typography variant="h3">Hold on while we log you out.</Typography>
                        <CircularProgress color="secondary" />
                    </>
                )}
                
                {logOutError !== undefined && (
                    <Typography variant="body1">Whoops, unable to log you out. Please try again.</Typography>
                )}

            </Box>
        </Container>
    );
}