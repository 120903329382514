import { OAuthExtension } from '@magic-ext/oauth';
import { Magic } from 'magic-sdk';

const m = new Magic('pk_live_DF7EF0F14B774654',{ 
    extensions: [ new OAuthExtension() ]
});

const getClient = () => {
    return m;
}

export const magic = {
    getClient
};