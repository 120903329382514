export const brandColors = {
    limeGreen: '#BFF43B',
    blue: '#3BD2F4',
    blurple: '#5D3BF4',
    orange: '#F45D3B',
    black: '#000000',
    white: '#FFFFFF'
};

export const gradients = {
    button: `linear-gradient(128.17deg, ${brandColors.orange} -14.78%, ${brandColors.limeGreen} 110.05%)`
}

export const brandFonts = {
    DWH: 'Druk Wide Heavy',
    DWS_ITALIC: 'DWS Italic',
    DW_Medium: 'Druk Wide Medium',
    DW_Super: 'Druk Wide Super'
}