import { Drop, NFT, User, UserFollow } from "../../types/api/types";
import { httpsClient } from "./client";

interface IMXRegistrationDetails {
    registration: {
        starkContractAddress : string;
        registrationContractAddress : string;
        apiURL : string;
    };
}

const getDetails = async () : Promise<IMXRegistrationDetails> => {
    return new Promise<IMXRegistrationDetails>( (resolve, reject) => {
        httpsClient.client.get(`/immutable/details`).then( (response : {data: IMXRegistrationDetails}) => {
            resolve(response.data);
        }).catch( (error) => {
            reject(error);
        })
    });
};


export const imxService = {
    getDetails
};