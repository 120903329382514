import axios, { AxiosRequestConfig } from "axios";
import { JWT_KEY } from "../../constants/auth";

let apiHost: string = (process.env.REACT_APP_API_URL || "").trim(); //"http://localhost:3000/api";
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
const client = axios.create({
    baseURL: apiHost,
    /*
    transformRequest: (data : any, headers : any) => {
        try {
            let currentAuthHeader = client.defaults.headers.common['Authorization'];

            if (currentAuthHeader === "" || currentAuthHeader === undefined || currentAuthHeader === null) {
                // Check the secure store
                let val = localStorage.getItem(JWT_KEY);
                client.defaults.headers.common['Authorization'] = `Bearer ${val}`;
                headers['Authorization'] = `Bearer ${val}`;
            } else {
                headers['Authorization'] = currentAuthHeader
            }
        } catch( error ) {
            console.log(error);
        } finally {
            return data;
        }
    },
    transformResponse: (data : any, headers : any) => {
        let authorizationHeader = headers["authorization"];
        if(authorizationHeader == "" || authorizationHeader == null || authorizationHeader == undefined) {
            authorizationHeader = headers['Authorization'];
        }
        
        if (authorizationHeader === "" || authorizationHeader === undefined || authorizationHeader === null) {
            return data;
        }
        client.defaults.headers.common['Authorization'] = authorizationHeader
    
        // Anytime we get an authorization header, store it in secure store
        if (authorizationHeader.split(' ').length === 2) {
            // This will asynchronously store it and is technically subject to race conditions.
            // However, because we set the client default header, it should be immediately available
            // on future requests.
            localStorage.setItem(JWT_KEY, authorizationHeader.split(' ')[1].trim());
        }
        return data;
    }
    */
});


// Check if we have put in any authorization header yet, and if not, if it's stored we will
client.interceptors.request.use( (request) => {
    return new Promise<AxiosRequestConfig>( (resolve, reject) => {  
        try {
            let currentAuthHeader = client.defaults.headers.common['Authorization'];

            if (currentAuthHeader === "" || currentAuthHeader === undefined || currentAuthHeader === null) {
                // Check the secure store
                let val = localStorage.getItem(JWT_KEY);
                client.defaults.headers.common['Authorization'] = `Bearer ${val}`;
                request.headers['Authorization'] = `Bearer ${val}`;
                resolve(request);
            } else {
                request.headers['Authorization'] = currentAuthHeader
                resolve(request);
            }
        } catch( error ) {
            reject(error);
        }
    })
}, (error) => {
    console.log('Got error with this interceptor ', error)
})

// Read off the headers of the response and if there is a JWT, then update and set it
client.interceptors.response.use( (response) => {    
    
    let authorizationHeader = response.headers["authorization"];
    if(authorizationHeader == "" || authorizationHeader == null || authorizationHeader == undefined) {
        authorizationHeader = response.headers['Authorization'];
    }
    
    if (authorizationHeader === "" || authorizationHeader === undefined || authorizationHeader === null) {
        return response;
    }
    client.defaults.headers.common['Authorization'] = authorizationHeader

    // Anytime we get an authorization header, store it in secure store
    if (authorizationHeader.split(' ').length === 2) {
        // This will asynchronously store it and is technically subject to race conditions.
        // However, because we set the client default header, it should be immediately available
        // on future requests.
        localStorage.setItem(JWT_KEY, authorizationHeader.split(' ')[1].trim());
    }
    
    return response;
}, (error) => {
    console.log(`Response Interceptor error ${error}`)
    return Promise.reject(error);
});

export const httpsClient = {
    client
};