import { Alert, Avatar, Box, Button, Chip, CircularProgress, Container, Grid, IconButton, Link, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';

import { useAppSelector } from '../../hooks';

import {Helmet} from "react-helmet";
import { redemptionService } from '../../clients/backend/redemptions';
import { NFTRedemption } from '../../types/api/types';
import { convertS3 } from '../../utils/transformers/s3uri';
import NftCard from '../../components/nfts/card';
import { brandColors, brandFonts } from '../../utils/constants/colors';
import { LoadingButton } from '@mui/lab';

const useStyles = makeStyles({
    primary: {
        margin: 20
    }
});

type RedemptionShowParams = {
    redemptionId : string;
}

export default function RedemptionShow() {
    const user = useAppSelector( (state) => state.counter.user);
    let { redemptionId } = useParams<RedemptionShowParams>();
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [pageErrors, setPageErrors] = useState<string[]>([]);
    const [redemption, setRedemption] = useState<NFTRedemption | undefined>(undefined);
    const [isRedeeming, setIsRedeeming] = useState<boolean>(false);

    let classes = useStyles();


    const loadRedemption = async () => {
        setLoadingData(true);

        redemptionService.getByKey(redemptionId).then( (result) => {
            setRedemption(result);
        }).catch( (error) => {
            setPageErrors([`Unable to fetch the redemption with the provided key '${redemptionId}.`])
        }).finally( () => {
            setLoadingData(false);
        })
        
    };

    const redeem = async () => {
        setIsRedeeming(true);
        setPageErrors([]);
        redemptionService.redeem(redemptionId).then( (result) => {
            setRedemption(result);
        }).catch( (error) => {
            setPageErrors([`Unable to redeem successfully. Please refresh the page and try again.`])
        }).finally( () => {
            setIsRedeeming(false);
        })
    }

   

    useEffect( () => {
        //loadProfile();
    }, []);

    useEffect( () => {
        loadRedemption();
    }, [redemptionId, user]);


    
    return (
        <Container>
            <Helmet>
                <title>Momentous - Beta :: Profile</title>
                <meta name="description" content="Momentous - NFTs for everyone" />
                <meta name="og:title" content="Momentous - beta :: Profile" />
                <meta name="og:description" content="Momentous - NFTs for everyone. Mint, Share, and Claim your favorite NFTs instantly with Momentous!" />
                <meta name="og:image" content={`${user?.profileImageURI}`} />
            </Helmet>
            {pageErrors.length > 0 && (
                pageErrors.map( (e) => (
                    <Alert severity="error" style={{marginTop: 20}}>{e}</Alert>
                ))
            )}
            {loadingData && (
                <Box>
                    <Typography variant="body1">Refreshing Redemption Data</Typography>
                    <CircularProgress />
                </Box>
            )}
            {redemption && (
                <Box className={classes.primary}>
                    <Grid container justifyContent="center" flexDirection="row" alignItems="center" >
                        <Grid item xs={12} sm={6}>
                            {redemption.nft && (
                            <NftCard nft={redemption.nft} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            {redemption.status === 'created' && (
                                <Box >
                                    <Typography>You have received a redemption link for this NFT. Click the button below to redeem into your account.</Typography>
                                    <LoadingButton title="Redeem" onClick={() => {
                                        redeem();
                                    }}
                                        variant="contained"
                                        style={{margin: 20}}
                                        disabled={isRedeeming || loadingData || user === undefined}
                                        loading={isRedeeming}
                                    >
                                        Redeem
                                    </LoadingButton>
                                    <br/>
                                    {user === undefined && (
                                    <Link href="https://www.onmomentous.io/#Early-Access" target="_blank" onClick={() => {
                                        localStorage.setItem("REDIRECT_LOCATION", `/redemptions/${redemptionId}`)
                                    }} style={{color: brandColors.orange}}>Want this? Join the Beta!</Link>
                                    )}
                                </Box>
                            )}
                            {redemption.status !== 'created' && (
                                <>
                                    <Typography>You have received a redemption link for this NFT. However, this NFT is no longer able to be redeemed.</Typography>
                                    {redemption.redeemerID === user?.id && (
                                        <Typography style={{
                                            fontFamily: brandFonts.DWH,
                                            marginTop: 20
                                        }}>The good news is that <span style={{color: brandColors.orange }}>you</span> were the one who already <span style={{color: brandColors.orange }}>redeemed</span> it!</Typography>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}
            
        </Container>


        
    );
}