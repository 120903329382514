import { Drop, NFT, User, UserFollow } from "../../types/api/types";
import { httpsClient } from "./client";

const getByID = async(userID : string) : Promise<User> => {
    if (userID.length === 0) return new Promise<User>( (_, reject) => {
       reject('Invalid User ID provided');
    });

    return new Promise<User>( (resolve, reject) => {
        httpsClient.client.get(`/users/${userID}/details`).then( (result : {data: {user : User}}) => {
            resolve(result.data.user);
        }).catch( (error) => {
            reject( `Error getting this user`);
        })
    });
};

const getDetails = async () : Promise<User> => {
    return new Promise<User>( (resolve, reject) => {
        httpsClient.client.get(`/talent/details`).then( (response) => {
            let user : User = response.data.user;
            resolve(user);
        }).catch( (error) => {
            reject(error);
        })
    });
};


interface DiscoveryResult {
    interestingFollows: User[];
    interestingNFTs: NFT[];
    interestingDrops: Drop[];
};
interface GetInterestingFollowsResult {
    data: {
        interestingUsers: User[],
        interestingNFTs: NFT[],
        interestingDrops: Drop[]
    }
};
const discover = async() : Promise<DiscoveryResult> => {
    return new Promise<DiscoveryResult>( (resolve, reject) => {
        httpsClient.client.get("/users/discover").then( (result : GetInterestingFollowsResult) => {
            resolve({
                interestingFollows: result.data.interestingUsers,
                interestingNFTs: result.data.interestingNFTs,
                interestingDrops: result.data.interestingDrops
            });
        }).catch( (error) => {
            reject( `Error discovering interesting users`);
        })
    });
};

const getDropsFromFollows = async(userID : string) : Promise<Drop[]> => {
    return new Promise<Drop[]>( (resolve, reject) => {
        httpsClient.client.get(`/users/${userID}/follows/drops`).then( (result : {data: {drops: Drop[]}}) => {
            resolve(result.data.drops);
        }).catch ( (error) => {
            reject( `Error getting drops for user follows` );
        })
    });
};

interface APIResult_UserFollow {
    data: UserFollow
};
const follow = async(followID : string) : Promise<UserFollow> => {
    return new Promise<UserFollow>( (resolve, reject) => {
        httpsClient.client.post(`/users/${followID}/follow`).then( (result : APIResult_UserFollow) => {
            resolve(result.data);
        }).catch( (error) => {
            reject( `Error following user` );
        });
    })
};

interface APIResult_UserUnfollow {
    data: {
        unfollow: boolean;
    };
};
const unfollow = async(followID : string) : Promise<boolean> => {
    return new Promise<boolean>( (resolve, reject) => {
        httpsClient.client.post(`/users/${followID}/unfollow`).then( (result : APIResult_UserUnfollow) => {
            resolve(result.data.unfollow);
        }).catch( (error) => {
            reject( `Error following user` );
        })
    })
};


export const userService = {
    getDetails,
    discover,
    getDropsFromFollows,
    getByID,
    follow,
    unfollow
};