import { Box, CircularProgress, Container, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { authService } from "../../clients/backend/auth";
import { imxService } from "../../clients/backend/imx";
import { userService } from "../../clients/backend/users";
import { magic } from "../../clients/magic/client";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loadUserDetails, setRedirectLocation } from "../../slices/user";
import { User } from "../../types/api/types";
import * as IMXLib from '@imtbl/imx-sdk';
import { ethers } from 'ethers';
import { useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";





export default function LoginCallback() {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const redirectLocation = useAppSelector( (state) => state.counter.redirectString);
    const [loggingIn, setLoggingIn] = React.useState<boolean>(false);
    const [logInError, setLogInError] = React.useState<string | undefined>(undefined);
    const [loggedIn, setLoggedIn] = React.useState<boolean>(false);


    useEffect(() => {
        setLoggingIn(true);
        setLogInError(undefined);
        setLoggedIn(false);
        let m = magic.getClient();
        m.oauth.getRedirectResult().then ( (result) => {
            
            if (result === undefined || result === null) {
                alert('Error logging in');
                return;
            }
            let picture = "";
            let provider = result?.oauth.provider;
            console.log(`Got Provider: ${provider}`)
            switch (provider) {
                case 'facebook':
                    let sources = result?.oauth.userInfo.sources;
                    if(sources) {
                        let mainDetails = sources[Object.keys(sources)[0]];
                        picture = mainDetails.picture.data.url;
                    }
                    
                    break;
                case 'google':
                    picture = `${result?.oauth.userInfo.picture}`;
                    break;
                case 'twitter':
                    console.log(`Twitter Profile: ${result?.oauth.userInfo.profile}`)
                    picture = `${result?.oauth.userInfo.profile}`;
                    break;
            }
            console.log("Starting login");
            // We have the result, so now we need to log you in
            authService.login({
                didt: `${result?.magic.idToken}`,
                provider: `${result?.oauth.provider}`,
                providerID: `${result?.oauth.userHandle}`,
                name: `${result?.oauth.userInfo.name}`,
                email: `${result?.magic.userMetadata.email}`,
                handle: `${result?.oauth.userInfo.name}`,
                pictureUrl: picture
            }).then( (res) => {
                console.log(`Successful login`);
                userService.getDetails().then( (user : User) => {
                    dispatch(loadUserDetails(user));
                  }).catch( (error) => {
                    //alert("User is logged out");
                    dispatch(loadUserDetails(undefined));
                  }).finally(() => {
                    setLoggedIn(true);
                    
                    let redirectPathname : string | undefined = "/";
                    try {
                        let rdl = localStorage.getItem("REDIRECT_LOCATION");
                        if(rdl !== null) {
                            redirectPathname = rdl;
                            localStorage.removeItem("REDIRECT_LOCATION");
                        }
                    } catch (error) {
                        console.log(error);
                    }
                    
                    history.push(redirectPathname)
                    
                    // Toss this into the background
                    registerUserWithIMX(m);

                    
                  });
                
            }).catch( (e) => {
                console.log(`Error on momentous login ${e}`)
            }).finally( () => {
                setLogInError(`Unable to log you in. Please try again.`);
            })
            // Log in to momentous backend, get jwt via cookie
        }).catch((error) => {
            console.log('Got error', error);
            setLoggingIn(false);
            setLogInError(`${error}`);
        }).finally( () => {
            
        });
    }, []);

    const registerUserWithIMX = async (m : any ) => {
        try {   
            const provider = new ethers.providers.Web3Provider(m.rpcProvider as any);
            const signer = provider.getSigner();

            let imxDetails = await imxService.getDetails();

            let starkContractAddress = imxDetails.registration.starkContractAddress;
            let registrationContractAddress = imxDetails.registration.registrationContractAddress;
            let imxApiUrl = imxDetails.registration.apiURL;

            
            const client = {
                publicApiUrl: imxApiUrl,
                starkContractAddress: starkContractAddress,
                registrationContractAddress: registrationContractAddress,
                gasLimit: '5000000',
                gasPrice: '20000000000',
            };

            const minter = await IMXLib.ImmutableXClient.build({
                ...client,
                signer: signer
            });

            console.log("Checking registration with IMX", minter.address);
            const registerImxResult = await minter.registerImx({
                etherKey: minter.address.toLowerCase(),
                starkPublicKey: minter.starkPublicKey,
            });

            console.log(registerImxResult);
        
            if (registerImxResult.tx_hash === '') {
                console.log("Finished IMX Registration Check");
            } else {
                const receipt = await provider.waitForTransaction(registerImxResult.tx_hash);
                console.log("Finished IMX Registration Check");
            }
        } catch (error) {
            console.log("Registration error", error);
        }
    }

    

    return (
        <Container>
            <Helmet>
                <title>Momentous - Beta :: Login Callback</title>
                <meta name="description" content="Momentous - NFTs for everyone" />
                <meta name="og:title" content="Momentous - beta :: Login Callback" />
                <meta name="og:description" content="Momentous - NFTs for everyone. Mint, Share, and Claim your favorite NFTs instantly with Momentous!" />
            </Helmet>
            <Box>
                <Typography variant="h3">Great! We're just logging you in!</Typography>
                <CircularProgress color="secondary" />
                
                {logInError !== undefined && (
                    <Typography variant="body1">Whoops, unable to log you in. Please try again.</Typography>
                )}

            </Box>
        </Container>
    );
}