import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Link,
    Paper,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { useEffect } from "react";
import { userService } from "../../clients/backend/users";
import DropCard from "../../components/drops/card";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { increment } from "../../slices/user";
import { Drop, User } from "../../types/api/types";
import { brandColors, brandFonts, gradients } from "../../utils/constants/colors";
import Alert from '@mui/material/Alert';
import UserCard from "../../components/users/card";
import { Helmet } from "react-helmet";


const useStyles = makeStyles({
    sectionHeader: {
        fontFamily: brandFonts.DW_Medium,
        textAlign: 'left',
        marginBottom: '1em',
        marginTop: '1em'
    },
    sectionHeaderDetail: {
        borderColor: 'black',
        //borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 8,
        marginLeft: 5,
        fontFamily: 'DWS Italic',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 2,
        paddingBottom: 2
    },
    mintersOfInterestContainer: {
        marginBottom: '0.75em'
    },
    moreLink: {
        color: brandColors.black,
        textTransform: 'none',
        marginTop: '1em',
        marginBottom: '1em',
        fontWeight: 'bold',
        fontSize: 10,
        position: 'absolute',
        bottom: 0,
        right: 0
    }
  });


export default function HomeIndex() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const count = useAppSelector( (state) => state.counter.value);
    const user = useAppSelector( (state) => state.counter.user);

    const [followerDrops, setFollowerDrops] = React.useState<Drop[]>([]);
    const [pageError, setPageError] = React.useState<string[]>([]);
    const [dropsAreLoading, setDropsAreLoading] = React.useState<boolean>(false);
    const [discoveryIsLoading, setDiscoveryIsLoading] = React.useState<boolean>(false);
    const [interestingUsers, setInterestingUsers] = React.useState<User[]>([]);

    // Load the data for the page
    useEffect( () => {
        loadPageData();
    }, [user]);

    useEffect( () => {
        loadPageData();
    }, []);


    const loadPageData = () => {
        setPageError([]);
        setDropsAreLoading(true);
        setDiscoveryIsLoading(true);

        if(user != undefined) {
            userService.getDropsFromFollows(user.id).then( (res) => {
                setFollowerDrops(res);
            }).catch( (error) => {
                setPageError(pageError.concat(["Unable to find drops from your followers. If the problem persists please try logging out and back in again."]));
            }).finally(() => {
                setDropsAreLoading(false);
            });

            // Also discover relevant data
            userService.discover().then( (res) => {
                setInterestingUsers(res.interestingFollows);
            }).catch( (error) => {
                console.log(`Discovery Error: ${error}`)
                setPageError(pageError.concat(["General discover for your timeline had an issue. If the problem persists please try logging out and back in again."]));
            }).finally(() => {
                setDiscoveryIsLoading(false);
            })

        } else {
            
            userService.discover().then( (res) => {
                setInterestingUsers(res.interestingFollows);
                setFollowerDrops(res.interestingDrops);
            }).catch( (error) => {
                console.log(`Discovery Error: ${error}`)
                setPageError(pageError.concat(["General discover for your timeline had an issue. If the problem persists please try logging out and back in again."]));
            }).finally(() => {
                setDiscoveryIsLoading(false);
                setDropsAreLoading(false);
            })
            // If user is undefined, they are logged out and we should find general interesting drops
        }
    };


    return (
        <Box>
            <Helmet>
                <meta name="og:image" content="/logo512.png" />
            </Helmet>
            <Box style={{
                backgroundColor: 'black',
                paddingBottom: 100,
                paddingTop: 50
            }}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h3"
                                style={{
                                    fontFamily: brandFonts.DWH,
                                    color: 'white',
                                    textAlign: 'left',
                                    margin: 0
                                }}
                                sx={{
                                    fontSize: {
                                        xs: 35,
                                        sm: 50
                                    }
                                }}
                            >OWN YOUR</Typography>
                            <Typography variant="h3"
                                style={{
                                    fontFamily: brandFonts.DWS_ITALIC,
                                    color: 'black',
                                    WebkitTextStrokeWidth: '2px',
                                    WebkitTextStrokeColor: brandColors.white,
                                    letterSpacing: 3,
                                    textAlign: 'left',
                                    margin: 0,
                                    marginTop: -13
                                }}
                                sx={{
                                    fontSize: {
                                        xs: 35,
                                        sm: 50
                                    },
                                    marginTop: {
                                        xs: 0
                                    }
                                }}
                            >MOMENTS</Typography>
                            <Box style={{
                                marginTop: 15
                            }}>
                                <Typography variant="h6" style={{
                                    color: brandColors.white,
                                    textAlign: 'left',
                                }}>
                                    Capture, mint, and share life's great moments.
                                </Typography>
                                <Typography variant="h6" style={{
                                    color: brandColors.white,
                                    textAlign: 'left',
                                    marginTop: -5
                                }}>
                                    This is Social Minting.
                                </Typography>
                            </Box>
                            <Box style={{marginTop: 20, textAlign: 'left'}}>
                                <Button variant="outlined" style={{
                                    borderRadius: 50,
                                    borderColor: brandColors.white,
                                    paddingTop: 15,
                                    paddingBottom: 15,
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    fontFamily: 'DM Sans',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    fontSize: 18,
                                    color: 'white',
                                    letterSpacing: 1
                                }}
                                    href="#drop-start"
                                >
                                    Explore
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box style={{justifyContent: 'center', alignItems: 'center'}}>
                                <img src="https://talent-media-dev.s3.amazonaws.com/photo-1642705149000-967072588.jpg" 
                                    style={{
                                        width: '80%',
                                        borderRadius: 10
                                }}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                
            </Box>
            <Container>
                {pageError.length > 0 && (
                    pageError.map( (e) => (
                        <Alert severity="error">{e}</Alert>
                    ))
                )}
                

                
                <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <Box style={{flex: 1, justifyContent: 'center'}}>
                        <Typography id="drop-start" variant="h6" className={classes.sectionHeader}>
                            RECENT CREATIONS
                        </Typography>
                    </Box>
                    <Box style={{flex: 1, textAlign: 'right', position: 'relative'}}>
                        
                        <Button
                        className={classes.moreLink}
                        href="/drops"
                        color='secondary'
                        >View All</Button>
                    </Box>
                </Box>
                
                {dropsAreLoading && (
                    <Box style={{minHeight: 90}}>
                        <Typography variant="body1">Refreshing Data</Typography>
                        <CircularProgress />
                    </Box>
                )}
                <Grid container spacing={3}>
                    
                    { followerDrops.slice(0,4).map((drop, i)=> {
                        return (
                            <Grid item xs={12} sm={6} md={3} key={`dropcard-${i}`}>
                                <DropCard 
                                    drop={drop}
                                    key={drop.id}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                

                <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <Box style={{flex: 1, justifyContent: 'center'}}>
                        <Typography id="drop-start" variant="h6" className={classes.sectionHeader}>
                            SUGGESTED USERS
                        </Typography>
                    </Box>
                    <Box style={{flex: 1, textAlign: 'right', position: 'relative'}}>
                        
                        <Button
                        className={classes.moreLink}
                        href="/search"
                        color='secondary'
                        >View All</Button>
                    </Box>
                </Box>
                {discoveryIsLoading && (
                    <Box style={{minHeight: 90}}>
                        <Typography variant="body1">Refreshing Discovery Data</Typography>
                        <CircularProgress />
                    </Box>
                )}
                <Box style={{
                    textAlign: 'left',
                    color: 'black'
                }}>
                    <Grid container spacing={2} style={{justifyContent: 'space-around'}}>
                        { interestingUsers.slice(0,5).map((iu, i) => {
                            return (
                                <Grid item xs={12} sm={4} md={12/5.0} className={classes.mintersOfInterestContainer}>
                                    <UserCard user={iu} key={`minters-${iu.id}`} />
                                </Grid>
                            );
                        })} 
                </Grid>
                </Box>
                

                    {/*
                <Typography variant="h3" className={classes.sectionHeader}>NFTs
                    <Typography display="inline" variant="subtitle1" color="secondary"
                    className={classes.sectionHeaderDetail}
                    >of your own</Typography>
                </Typography>
                <Box style={{
                    textAlign: 'left',
                    color: 'black'
                }}>
                    <ImageList rowHeight={300} cols={3}>
                        { 'abcdefghi'.split('').map( (_, i) => { 
                            let colLength = 1;
                            if (i == 0) {colLength = 3}
                            if (i == ('abcdefghi'.length - 1)) { colLength = 2}
                            return (
                                <ImageListItem key={`il-${i}`} cols={colLength}>
                                    <Link href="/profiles/0x0393939/0x38292982" underline="hover">
                                        <img src={`https://picsum.photos/900?dog=${Math.floor(Math.random()*100)}`} />
                                    </Link>
                                    <ImageListItemBar 
                                        title="NFT.xyz"
                                        subtitle={Math.random() < 0.5 ? 'Claimed from @vwilliams' : 'Minted by you'}
                                    />
                                </ImageListItem>
                            );})}
                    </ImageList>


                </Box>
                <Link
                    className={classes.moreLink}
                    href="/profiles/0x083497bde29f/nfts"
                    color='secondary'
                    variant='button'
                    underline="hover">More NFTs</Link>
                            */}

            </Container>
        </Box>
    );
}


