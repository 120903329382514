import { Alert, Avatar, Box, Button, CircularProgress, Container, Divider, Grid, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { brandColors, brandFonts, gradients } from '../../utils/constants/colors';
import {dropService} from '../../clients/backend/drops';
import { Drop, NFT } from '../../types/api/types';
import { useAppSelector } from '../../hooks';
import { LoadingButton } from '@mui/lab';
import { nftService } from '../../clients/backend/nft';
import {Helmet} from "react-helmet";
import { Facebook, Twitter } from '@mui/icons-material';
import { convertS3 } from '../../utils/transformers/s3uri';


const useStyles = makeStyles({
    dropDetailBox: {
        marginTop: 100,
        marginBottom: 100
    }
});

type NftDropShowParams = {
    dropId : string;
    nftId : string;
}

export default function NftDropShow() {
    const user = useAppSelector( (state) => state.counter.user);
    let classes = useStyles();
    let { dropId, nftId } = useParams<NftDropShowParams>();

    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [pageErrors, setPageErrors] = useState<string[]>([]);
    const [nft, setNft] = useState<NFT | undefined>(undefined);
    const [isClaiming, setIsClaiming] = useState<boolean>(false);

    const loadNftData = async () => {
        setPageErrors([]);
        setLoadingData(true);

        nftService.getNFTByID(nftId).then( (res) => {
            setNft(res);
        }).catch( (error) => {
            console.log("Propogating here?")
            setPageErrors(["Error loading drop data for the page. Please reload the page and try again."])

        }).finally( () => {
            setLoadingData(false);

        })
    };

    useEffect( () => {
        //loadNftData();
    }, []);

    useEffect( () => {
        loadNftData();
    }, [dropId]);

    useEffect( () => {
    }, [user, nft]);

    return (
        <Container>
            <Helmet>
                <title>Momentous - Beta :: NFT</title>
                <meta name="description" content="Momentous - NFTs for everyone" />
                <meta name="og:title" content="Momentous - beta :: NFT" />
                <meta name="og:description" content="Momentous - NFTs for everyone. Mint, Share, and Claim your favorite NFTs instantly with Momentous!" />
                <meta name="og:image" content={`${convertS3(nft?.mediaEntity?.previewS3URI)}`} />
            </Helmet>
            {(pageErrors.length > 0) && (
                pageErrors.map( (e) => (
                    <Alert severity="error" style={{marginTop: 20}}>{e}</Alert>
                ))
            )}
            {loadingData && (
                <Box style={{marginTop: 20}}>
                    <Typography variant="body1">Refreshing NFT Data</Typography>
                    <CircularProgress />
                </Box>
            )}

            {nft && (
            <>
            <Box className={classes.dropDetailBox}>
                <Grid container spacing={10}>
                    <Grid item xs={12} sm={6}>
                        <Paper elevation={0}>
                            {nft.mediaEntity?.type === 'video' && (
                                <video controls muted playsInline={true} 
                                    onError={(e) => alert("Unable to load this video.")}
                                    style={{width: '100%'}}
                                    poster={convertS3(nft.mediaEntity.previewS3URI)}>
                                    <source src={convertS3(nft.mediaEntity.s3URI)} type={nft.mediaEntity?.mediaType === 'video/quicktime' ? 'video/mp4' : nft.mediaEntity?.mediaType} />
                                    <p>Sorry, your browser does not support {nft.mediaEntity.mediaType} videos.</p>
                                </video>
                            )}
                            {nft.mediaEntity?.type === 'image' && (
                                <img src={nft.mediaEntity?.s3URI != "" ? convertS3(nft.mediaEntity?.previewS3URI): 'https://momentous-brand-assets.s3.us-east-2.amazonaws.com/Momentous-Icon-White-Transparent%402x.png'} 
                                    style={{
                                        width: '100%',
                                        borderRadius: '10px'
                                    }}
                                />
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{
                        textAlign: 'left',
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        
                    }}>
                        
                            
                            <Typography variant="h4" style={{
                                fontFamily: brandFonts.DW_Medium,
                            }}>
                                <Link href={`/drops/${nft.drop.id}`} underline="hover" style={{color: 'black'}}>{nft.name}</Link>
                            </Typography>
                            <Box style={{marginBottom: 30}}>
                                <Box style={{justifyContent: 'center', display: 'inline-block'}}>
                                    <Avatar alt={nft.drop.user.name} src={nft.drop.user.profileImageURI || "https://app.onmomentous.io/newicon.png"} 
                                        style={{
                                            backgroundColor: 'black',
                                            width: 30,
                                            height: 30,
                                            bottom: -10
                                        }}
                                        />
                                </Box>
                                <Box style={{display: 'inline-block', justifyContent: 'flex-start',verticalAlign: 'middle', marginLeft: 10}}>
                                    <Typography variant="body2" style={{
                                        fontFamily: 'DM Sans',
                                        fontWeight: 400,
                                        
                                        verticalAlign: 'middle',
                                        fontSize: 16
                                    }}>
                                        Created by <Link style={{fontWeight: 700}} color="inherit" href={`/profiles/${nft.user.id}`} underline='hover'>{nft.user.id === user?.id ? 'you' : nft.user.name}</Link>
                                    </Typography>
                                </Box>
                            </Box>

                            <Box style={{display: 'flex', flexDirection: 'row', width: '70%'}}>
                                <Box style={{flex: 1}}>
                                    <Typography style={{fontSize: 14}}>Drop Date:</Typography>
                                    <Typography style={{fontWeight: 700}}>{new Date(nft.createdAt).toLocaleDateString()}</Typography>
                                </Box>
                                <Box style={{flex: 1}}>
                                    <Typography style={{fontSize: 14}}>Editions:</Typography>
                                    <Typography style={{fontWeight: 700}}>{nft.numberOfEditions}</Typography>
                                </Box>
                                <Box style={{flex: 1}}>
                                    <Typography style={{fontSize: 14}}>Serial #:</Typography>
                                    <Typography style={{fontWeight: 700}}>{nft.serialEdition}</Typography>
                                </Box>
                            </Box>

                            <Box style={{marginTop: 30}}>
                                <Typography style={{fontSize: 14}}>Owned by:</Typography>
                                <Typography style={{fontWeight: 700}}>
                                    <Link style={{fontWeight: 700}} color="inherit" href={`/profiles/${nft.owner.id}`} underline='hover'>
                                        {nft.owner.id === user?.id ? 'you' : nft.owner.name}
                                    </Link></Typography>
                            </Box>

                            <Box style={{marginTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                <Box style={{flex: 1}}>
                                    <IconButton size="small" style={{color: 'black'}} href={`https://twitter.com/intent/tweet?text=Check out this amazing NFT drop from @OnMomentous!&url=https://m.onmomentous.io/api/share/social?dropId=${nft.drop.id}&hashtags=nfts,drops,momentous`} target="_blank">
                                        <Twitter />
                                    </IconButton>
                                    {/* <IconButton size="small" style={{color: 'black'}}
                                    href={`https://www.facebook.com/sharer/sharer.php?u=https://app.onmomentous.io/drops/${nft.drop.id}`}
                                    target="_blank"
                                    >
                                        <Facebook />
                                    </IconButton> */}
                                </Box>
                                
                            </Box>

                            <Box style={{
                                borderTop: 'solid 1px black',
                                borderBottom: 'solid 1px black',
                                paddingTop: 30,
                                paddingBottom: 30,
                                marginBottom: 20,
                                marginTop: 30
                            }}>
                                <Typography>
                                    {nft.drop.description}
                                </Typography>
                            </Box>
                            
                            <Stack>
                           
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            
            <Box style={{maxHeight: '600px', overflowY: 'scroll', marginBottom: 150}}>
                <Typography variant="h5" style={{
                    fontFamily: brandFonts.DW_Medium,
                    textAlign: 'left'
                }}>Other Editions</Typography>
                <List >
                    {nft.drop.nfts.map( (nft, i) => (
                        <>
                        <ListItem alignItems="flex-start">
                            
                            <ListItemText 
                                primary={<>
                                    <Typography>
                                    <Link href={`/drops/${nft.drop.id}/${nft.id}`} color="secondary" style={{color: 'black', fontFamily: brandFonts.DW_Medium}} underline="hover"># {nft.serialEdition} / {nft.numberOfEditions}</Link> owned by 
                                    &nbsp;<Link href={`/profiles/${nft.owner.id}`} color="secondary" style={{color: 'black', fontWeight: 700}} underline="hover">@{nft.owner.name} {nft.owner.id == user?.id ? "(you)" : ""}</Link>
                                    </Typography>
                                </>
                                }
                                secondary={<Typography variant="subtitle2">Token ID: {nft.imxAssetID}</Typography>}
                            />
                        </ListItem>
                        {i !== (nft.numberOfEditions -1) && (
                            <Divider component="li" />
                        )}
                        </>
                    ))}
                    
                </List>
            </Box>
            
            </>
            )}


           
        </Container>


        
    );
}