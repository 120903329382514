import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, Box, IconButton, Link } from '@mui/material';
import { Facebook, FavoriteBorderOutlined, Gradient, Share, Twitter } from '@mui/icons-material';
import { Drop, NFT } from '../../types/api/types';
import { convertS3 } from '../../utils/transformers/s3uri';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    boxShadow: 'none'
  },
  media: {
    borderRadius: 10
  },
});

interface NftCardProps {
  nft : NFT
};

export default function NftCard(props : NftCardProps) {
  const classes = useStyles();

  let nftLinkRoute = `/drops/${props.nft.drop.id}/${props.nft.id}`;
    
  return (
    <Card className={classes.root}>
      <CardActionArea href={nftLinkRoute}>
        <CardMedia
          className={classes.media}
          component="img"
          image={props.nft?.mediaEntity?.s3URI != "" ? convertS3(props.nft.mediaEntity?.s3URI): 'https://momentous-brand-assets.s3.us-east-2.amazonaws.com/Momentous-Icon-White-Transparent%402x.png'}
          title={`${props.nft.drop.name}`}
          style={{
            backgroundColor: 'black'
          }}
          
        />
        <CardContent style={{
          borderWidth: 0,
          paddingTop: 3,
          paddingLeft: 0,
          alignContent: 'left',
          textAlign: 'left'
        }}>
              <Link
                variant="body1"
                component="a"
                display="inline"
                color="textSecondary"
                style={{
                  textAlign: 'left',
                  fontSize: 14
                }}
                href={`/profiles/${props.nft.drop.user.id}`}
                underline="hover">
                  @{props.nft.drop.user.name}
              </Link>
            
            <Typography align="left" style={{
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: 0.25,
              marginBottom: 5,
              paddingTop: 5
            }}>
            {props.nft.drop.name} <small style={{color: '#888'}}>{props.nft.drop.claim ? '' : <>&nbsp;</>}</small>
          </Typography>
          <Typography align="left" color="textSecondary" style={{
            fontSize: 12,
            letterSpacing: 0.25
          }}>
            Edition #{props.nft.serialEdition} / {props.nft.numberOfEditions}
            </Typography>
        </CardContent>
      </CardActionArea>
     
    </Card>
  );
}