import { Alert, Avatar, Box, Button, CircularProgress, Container, Divider, Grid, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, Modal, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { brandColors, brandFonts, gradients } from '../../utils/constants/colors';
import {dropService} from '../../clients/backend/drops';
import { Drop, NFT } from '../../types/api/types';
import { useAppSelector } from '../../hooks';
import { LoadingButton } from '@mui/lab';
import { setRedirectLocation } from '../../slices/user';
import {Link as Link3} from 'react-router-dom'
import { Facebook, Twitter } from '@mui/icons-material';
import {Helmet} from "react-helmet";
import { convertS3 } from '../../utils/transformers/s3uri';


const useStyles = makeStyles({
   dropDetailBox: {
       marginTop: 100,
       marginBottom: 100
   }
});

type DropShowParams = {
    dropId : string;
}

export default function DropShow() {
    const user = useAppSelector( (state) => state.counter.user);
    let classes = useStyles();
    let { dropId } = useParams<DropShowParams>();

    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [pageErrors, setPageErrors] = useState<string[]>([]);
    const [drop, setDrop] = useState<Drop | undefined>(undefined);
    const [isClaiming, setIsClaiming] = useState<boolean>(false);
    const [hasClaimedAlready, setHasClaimedAlready] = useState<boolean>(false);
    const [hasJustClaimed, setHasJustClaimed] = useState<boolean>(false);
    const [claimedNFT, setClaimedNFT] = useState<NFT | undefined>(undefined);

    const loadDropData = async () => {
        setPageErrors([]);
        setLoadingData(true);
        dropService.getDropByID(dropId).then( (res : Drop) => {
            setDrop(res);
        }).catch( (error) => {
            setPageErrors(pageErrors.concat(["Error loading drop data for the page. Please reload the page and try again."]))
        }).finally( () => {
            setLoadingData(false);
        });
    };

    const claimDrop = async () => {
        if(drop == undefined) {
            alert("Somehow the drop is undefined. Please refresh the page and try again.");
            return;
        }
        setIsClaiming(true);
        setPageErrors([]);
        dropService.claim(drop.id).then( (res) => {
            setHasClaimedAlready(true);
            setClaimedNFT(res.nft);
            loadDropData();
            establishDropClaimStatus();
            setHasJustClaimed(true);
        }).catch( (error) => {
            setPageErrors(["Unable to claim drop. It may already be all claimed or the window is over. Try refreshing the page and checking again."]);
        }).finally( () => {
            setIsClaiming(false);
        });
    };

    const establishDropClaimStatus = async () => {
        let isClaimedLocally = false;
        user?.claimedNFTs.forEach( (cnft) => {
            if(cnft.dropClaimID === drop?.claim?.id) {
                isClaimedLocally = true;
            }
        });

        setHasClaimedAlready(isClaimedLocally);
    };

    useEffect( () => {
        //loadDropData();
        establishDropClaimStatus();
    }, []);

    useEffect( () => {
        loadDropData();
        establishDropClaimStatus();
    }, [dropId]);

    useEffect( () => {
        establishDropClaimStatus();
    }, [user, drop]);

    // @ts-ignore
    let { address } = useParams();
    return (
        <Container>
            <Helmet>
                {drop && (
                <title>Momentous - beta :: Drop by {drop?.user.name}</title>
                )}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@onmomentous" />
                <meta name="twitter:creator" content="@onmomentous" />
                <meta name="twitter:description" content={`${drop?.name} dropped ${drop?.nfts.length} NFTs using Momentous. Check them out now! With Momentous you can Mint, Share, and Claim NFTs instantly!`}/>
                <meta name="description" content={`${drop?.name} dropped ${drop?.nfts.length} NFTs using Momentous. Check them out now! With Momentous you can Mint, Share, and Claim NFTs instantly!`}/>
                <meta property="og:title" content={`Momentous NFT Drop: ${drop?.name}`} />
                <meta property="twitter:title" content={`Momentous NFT Drop: ${drop?.name}`} />
                <meta property="og:description" content={`${drop?.name} dropped ${drop?.nfts.length} NFTs using Momentous. Check them out now! With Momentous you can Mint, Share, and Claim NFTs instantly!`} />
                {drop?.nfts && drop?.nfts.length > 0 && (
                    <meta property="og:image" content={`${convertS3(drop?.nfts[0].mediaEntity?.previewS3URI)}`} />
                )}
                {drop?.nfts && drop?.nfts.length > 0 && (
                    <meta property="twitter:image" content={`${convertS3(drop?.nfts[0].mediaEntity?.previewS3URI)}`} />
                )}
            </Helmet>
            {pageErrors.length > 0 && (
                pageErrors.map( (e) => (
                    <Alert severity="error" style={{marginTop: 20}}>{e}</Alert>
                ))
            )}
            {loadingData && (
                <Box style={{marginTop: 20}}>
                    <Typography variant="body1">Refreshing Data</Typography>
                    <CircularProgress />
                </Box>
            )}

            <Modal open={hasJustClaimed} onBackdropClick={() => {
                setHasJustClaimed(false);
            }}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'rgba(0,0,0,0.8)',
                    border: '2px solid #000',
                    boxShadow: 24,
                    color: 'white',
                    p: 4,
                    textAlign: 'center',
                    alignItems: 'center',
                    }}>
                        <Box>
                            <Typography variant="h6">Congratulations! You just claimed an edition from this drop!</Typography>
                        </Box>
                        <Box style={{
                            borderTop: 'solid 1px #D2F43B',
                            borderBottom: 'solid 1px #D2F43B',
                            paddingTop: 5,
                            paddingBottom: 5,
                            marginTop: 20,
                            marginBottom: 20
                        }}>
                            <Typography variant="body1">Drop: {drop?.name}</Typography>
                            <Typography variant="body1">Creator: @{drop?.user.name}</Typography>
                            <Typography variant="body1">Serial Edition: {claimedNFT?.serialEdition}</Typography>
                        </Box>

                        <Box style={{margin: 10}}>
                            <Button variant="contained" startIcon={<Facebook />} style={{
                                backgroundColor: "rgb(66, 103, 178)",
                                color: 'white',
                                width: '100%'
                            }}
                                href={`https://www.facebook.com/sharer/sharer.php?u=https://m.onmomentous.io/api/share/social?dropId=${drop?.id}`}
                                target="_blank"
                            >
                                Share on Facebook
                            </Button>
                        </Box>
                        <Box style={{margin: 10}}>
                            <Button variant="contained" startIcon={<Twitter />} style={{
                                backgroundColor: "rgb(29, 161, 242)",
                                color: 'white',
                                width: '100%'
                            }}
                                href={`https://twitter.com/intent/tweet?text=I just claimed this amazing NFT drop from @OnMomentous!&url=https://m.onmomentous.io/api/share/social?dropId=${drop?.id}&hashtags=nfts,drops,momentous`}
                                target="_blank"
                            >
                                Share on Twitter
                            </Button>
                        </Box>

                        <Box>
                            <Typography>Want to mint your own drops and NFTs? Download the <Link href="https://apps.apple.com/us/app/momentous-nfts/id1594705169" target="_blank">Momentous app</Link> today!</Typography>
                        </Box>
                        
                </Box>
            </Modal>

            {drop && (
            <>
            <Box className={classes.dropDetailBox}>
                <Grid container spacing={10}>
                    <Grid item xs={12} sm={6}>
                        <Paper elevation={0}>
                            {drop.nfts.length >0 && (
                                <>
                                    {drop.nfts[0].mediaEntity?.type === 'video' && (
                                        <video controls muted playsInline={true} 
                                            onError={(e) => alert("Unable to load this video.")}
                                            style={{width: '100%'}}
                                            poster={convertS3(drop.nfts[0].mediaEntity.previewS3URI)}>
                                            <source src={convertS3(drop.nfts[0].mediaEntity.s3URI)} type={drop.nfts[0].mediaEntity?.mediaType === 'video/quicktime' ? 'video/mp4' : drop.nfts[0].mediaEntity?.mediaType} />
                                            <p>Sorry, your browser does not support {drop.nfts[0].mediaEntity.mediaType} videos.</p>
                                        </video>
                                    )}
                                    {drop.nfts[0].mediaEntity?.type === 'image' && (
                                        <img src={drop.nfts.length > 0 ? convertS3(drop.nfts[0].mediaEntity?.previewS3URI): 'https://momentous-brand-assets.s3.us-east-2.amazonaws.com/Momentous-Icon-White-Transparent%402x.png'} 
                                            style={{
                                                width: '100%',
                                                borderRadius: '10px'
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{
                        textAlign: 'left',
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        
                    }}>
                        
                            
                            <Typography variant="h4" style={{
                                fontFamily: brandFonts.DW_Medium,
                            }}>
                                {drop.name}
                            </Typography>
                            <Box style={{marginBottom: 30}}>
                                <Box style={{justifyContent: 'center', display: 'inline-block'}}>
                                    <Avatar alt={drop.user.name} src={drop.user.profileImageURI || "https://app.onmomentous.io/newicon.png"} 
                                        style={{
                                            backgroundColor: 'black',
                                            width: 30,
                                            height: 30,
                                            bottom: -10
                                        }}
                                        />
                                </Box>
                                <Box style={{display: 'inline-block', justifyContent: 'flex-start',verticalAlign: 'middle', marginLeft: 10}}>
                                    <Typography variant="body2" style={{
                                        fontFamily: 'DM Sans',
                                        fontWeight: 400,
                                        
                                        verticalAlign: 'middle',
                                        fontSize: 16
                                    }}>
                                        Created by <Link style={{fontWeight: 700}} color="inherit" href={`/profiles/${drop.user.id}`} underline='hover'>{drop.user.name}</Link>
                                    </Typography>
                                </Box>
                            </Box>

                            <Box style={{display: 'flex', flexDirection: 'row'}}>
                                <Box style={{flex: 1}}>
                                    <Typography style={{fontSize: 14}}>Drop Date:</Typography>
                                    <Typography style={{fontWeight: 700}}>{new Date(drop.createdAt).toLocaleDateString()}</Typography>
                                </Box>
                                <Box style={{flex: 3}}>
                                    <Typography style={{fontSize: 14}}>Editions:</Typography>
                                    <Typography style={{fontWeight: 700}}>{drop.nfts.length}</Typography>
                                </Box>
                            </Box>

                            <Box style={{marginTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                <Box style={{flex: 1}}>
                                    <IconButton size="small" style={{color: 'black'}} href={`https://twitter.com/intent/tweet?text=Check out this amazing NFT drop from @OnMomentous!&url=https://m.onmomentous.io/api/share/social?dropId=${drop.id}&hashtags=nfts,drops,momentous`} target="_blank">
                                        <Twitter />
                                    </IconButton>
                                    {/* <IconButton size="small" style={{color: 'black'}}
                                    href={`https://www.facebook.com/sharer/sharer.php?u=https://app.onmomentous.io/drops/${drop.id}`}
                                    target="_blank"
                                    >
                                        <Facebook />
                                    </IconButton> */}
                                </Box>
                                
                            </Box>

                            <Box style={{
                                borderTop: 'solid 1px black',
                                borderBottom: 'solid 1px black',
                                paddingTop: 30,
                                paddingBottom: 30,
                                marginBottom: 20,
                                marginTop: 30
                            }}>
                                <Typography>
                                    {drop.description}
                                </Typography>
                            </Box>
                            
                            <Stack>
                           

                            {/* {drop.claim != undefined && drop.claim.status == "open" && (drop.claim.userID !== user?.id) && (
                                <Box style={{marginTop: 20}}>
                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Box style={{flex: 1}}>
                                            <LoadingButton 
                                                style={{borderRadius: 40, width: '80%', verticalAlign: 'center', justifyContent: 'center', textTransform: 'none'}}
                                                variant="outlined" disabled={isClaiming || hasClaimedAlready || (user == undefined) || (drop.claim.claimedNFTs.length == drop.nfts.length)} loading={isClaiming}
                                                onClick={() => claimDrop()}
                                            >Claim an Edition</LoadingButton>
                                        </Box>
                                        <Box style={{flex: 1}}>
                                            { hasClaimedAlready && (
                                                <Typography variant="caption">You have already claimed this drop.</Typography>
                                            )}
                                            { !hasClaimedAlready && !(drop.claim.claimedNFTs.length == drop.nfts.length) && (
                                                <Typography variant="caption">This drop has a claim available. Claim before the window closes!</Typography>
                                            )}
                                            
                                            {(user == undefined) && (
                                                <Box>
                                                    <Link3 to="/login" style={{backgroundColor: 'black', borderRadius: 30, paddingLeft: 15, paddingRight: 15, paddingBottom: 4,  color: 'white'}}
                                                        onClick={() => {
                                                            setRedirectLocation(`/drops/${drop.id}`);
                                                            localStorage.setItem("REDIRECT_LOCATION", `/drops/${drop.id}`)
                                                        }}
                                                    >
                                                        <Typography variant="caption">Login or Create an Account to claim this.</Typography>
                                                    </Link3>
                                                </Box>
                                            )}
                                             {drop.claim && (
                                                <>
                                                <Box>
                                                    <Typography variant="caption">{drop.claim.claimedNFTs.length} editions were claimed!</Typography>
                                                </Box>
                                                {drop.claim?.claimedNFTs.length == drop.nfts.length && (
                                                    <Box>
                                                        <Typography variant="caption">All possible NFTs have been claimed already.</Typography>
                                                    </Box>
                                                )}
                                                {drop.claim.status != "open" && (
                                                    <Box>
                                                        <Typography variant="caption">The claim period for this drop is no longer open by the creator.</Typography>
                                                    </Box>
                                                )}
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                    
                                </Box>
                            )}
                            */}
                            

                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            
            <Box style={{maxHeight: '600px', overflowY: 'scroll', marginBottom: 150}}>
                <Typography variant="h5" style={{
                    fontFamily: brandFonts.DW_Medium,
                    textAlign: 'left'
                }}>Available Editions</Typography>
                <List >
                    {drop.nfts.map( (nft, i) => (
                        <>
                        <ListItem alignItems="flex-start">
                            
                            <ListItemText 
                                primary={<>
                                    <Typography>
                                    <Link href={`/drops/${drop.id}/${nft.id}`} color="secondary" style={{color: 'black', fontFamily: brandFonts.DW_Medium}} underline="hover"># {nft.serialEdition} / {nft.numberOfEditions}</Link> owned by 
                                    &nbsp;<Link href={`/profiles/${nft.owner.id}`} color="secondary" style={{color: 'black', fontWeight: 700}} underline="hover">@{nft.owner.name} {nft.owner.id == user?.id ? "(you)" : ""}</Link>
                                    </Typography>
                                </>
                                }
                                secondary={<Typography variant="subtitle2">Token ID: {nft.imxAssetID}</Typography>}
                            />
                        </ListItem>
                        {i !== (drop.nfts.length -1) && (
                            <Divider component="li" />
                        )}
                        </>
                    ))}
                    
                </List>
            </Box>
            
            </>
            )}
        </Container>


        
    );
}