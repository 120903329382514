import { Box, Button, CircularProgress, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { authService } from "../../clients/backend/auth";
import { imxService } from "../../clients/backend/imx";
import { userService } from "../../clients/backend/users";
import { magic } from "../../clients/magic/client";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loadUserDetails, setRedirectLocation } from "../../slices/user";
import { User } from "../../types/api/types";
import * as IMXLib from '@imtbl/imx-sdk';
import { ethers } from 'ethers';
import { useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";





export default function MintIndex() {
    const dispatch = useAppDispatch();
    


    useEffect(() => {
       
    }, []);

   

    return (
        <Container>
            <Helmet>
                <title>Momentous - Beta :: Mint</title>
                <meta name="description" content="Momentous - NFTs for everyone" />
                <meta name="og:title" content="Momentous - beta :: Mint" />
                <meta name="og:description" content="Momentous - NFTs for everyone. Mint, Share, and Claim your favorite NFTs instantly with Momentous!" />
            </Helmet>
            <Box style={{
                padding: 20
            }}>
                <Typography variant="h3" style={{
                    fontFamily: 'Druk Wide Heavy'
                }}>Mint NFTs with Momentous</Typography>
                <Container style={{
                    
                }}>
                    <Box style={{width: '60%', margin: '0 auto', paddingTop: 20}}>
                        <Typography variant="body1" >
                            The Momentous app is currently available as a native application for Apple Phones. Visit the App Store to download and install the app on your device. 
                        </Typography>
                        <Box style={{margin: 10}}>
                            <Button variant="contained" target="_blank" href="https://apps.apple.com/us/app/momentous-nfts/id1594705169">
                                Download Now on the App Store
                            </Button>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item sm={6}>
                                <img src="https://momentous-brand-assets.s3.us-east-2.amazonaws.com/momentous_app_store_01.png" 
                                    style={{width: '100%'}}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <img src="https://momentous-brand-assets.s3.us-east-2.amazonaws.com/momentous_app_store_02.png" 
                                    style={{width: '100%'}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <img src="https://momentous-brand-assets.s3.us-east-2.amazonaws.com/momentous_app_store_03.png" 
                                    style={{width: '100%'}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <img src="https://momentous-brand-assets.s3.us-east-2.amazonaws.com/momentous_app_store_04.png" 
                                    style={{width: '100%'}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <img src="https://momentous-brand-assets.s3.us-east-2.amazonaws.com/momentous_app_store_05.png" 
                                    style={{width: '100%'}}
                                />
                            </Grid>
                        </Grid>
                        
                        <Box style={{margin: 10}}>
                            <Button variant="contained" target="_blank" href="https://apps.apple.com/us/app/momentous-nfts/id1594705169">
                                Download Now on the App Store
                            </Button>
                        </Box>
                        <Typography variant="body1" >
                            With the Momentous app you can mint NFTs of all your favorite moments and share them with friends and family, or just keep them safely forever on the Ethereum blockchain.
                        </Typography>
                    </Box>
                </Container>
                
                
              
            </Box>
        </Container>
    );
}