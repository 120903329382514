import { Box, Button, Container, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Apple, Facebook, Mail, Twitter } from "@mui/icons-material";
import { authService } from "../../clients/backend/auth";
import { magic } from "../../clients/magic/client";
import { OAuthProvider } from "@magic-ext/oauth";
import {Helmet} from "react-helmet";


const useStyles = makeStyles({
    welcomeBox: {
        marginBottom: '2.5em'
    },
    loginButton: {
        width: '50%',
        minWidth: 250,
        maxWidth: 400,
        marginBottom: 10,
        marginTop: 10
    },
    loginButtonBox: {
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',
    }
});

export default function LoginIndex() {
    const classes = useStyles();

    const loginWithSocial = async (socialProvider : string) => {
        let m = magic.getClient();
        try {
            await m.oauth.loginWithRedirect({
                provider: socialProvider as OAuthProvider,
                redirectURI: new URL("/callback", window.location.origin).href
            });
        } catch (error) {
            alert(error)
        }
    }

    const loginWithGoogle = async () => {
        let m = magic.getClient();
        try {
            await m.oauth.loginWithRedirect({
                provider: 'google',
                redirectURI: new URL("/callback", window.location.origin).href
            });
        } catch (error) {
            alert(error)
        }
    }

    return (
        <Container>
            <Helmet>
                <title>Momentous - Beta :: Login</title>
                <meta name="description" content="Momentous - NFTs for everyone" />
                <meta name="og:title" content="Momentous - beta :: Login" />
                <meta name="og:description" content="Momentous - NFTs for everyone. Mint, Share, and Claim your favorite NFTs instantly with Momentous!" />
            </Helmet>
            <Box className={classes.welcomeBox}>
                <h1 style={{fontFamily: 'DWS'}}> Welcome!</h1>
                <Typography variant="body1">
                    Welcome to Momentous! Continue below to gain access to claiming and minting your own NFTs. Claiming and minting are completely free and done instanteously. You are clicks away from owning your moments!
                </Typography>
            </Box>

            <Box className={classes.loginButtonBox}>
                <Button 
                    variant="contained"
                    startIcon={<Apple/>}
                    className={classes.loginButton}
                    onClick={() => loginWithSocial('apple')}
                >
                    Continue with Apple
                </Button>
                <br/>

                <Button 
                    variant="contained"
                    startIcon={<Mail/>}
                    className={classes.loginButton}
                    onClick={() => loginWithSocial('google')}
                >
                    Continue with Google
                </Button>
                <br/>

                <Button 
                    variant="contained"
                    startIcon={<Facebook/>}
                    className={classes.loginButton}
                    onClick={() => loginWithSocial('facebook')}
                >
                    Continue with Facebook
                </Button>
                <br/>

                <Button 
                    variant="contained"
                    startIcon={<Twitter/>}
                    className={classes.loginButton}
                    onClick={() => loginWithSocial('twitter')}
                >
                    Continue with Twitter
                </Button>
            </Box>

            <Typography variant="subtitle1">*Wallet Connect coming soon.</Typography>

        </Container>
    )
}