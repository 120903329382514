import { httpsClient } from "./client";
import { Drop, DropClaimNFT, NFT, User } from "../../types/api/types";
import { resolve } from "dns";

interface SearchResult {
    data: {
        drops: Drop[];
        nfts: NFT[];
        users: User[];
    };
};

const search = async (query : string) : Promise<{result: SearchResult}> => {
    
    if (query === undefined) return new Promise<{result: SearchResult}>( (_, reject) => {
        reject('Invalid Search Query provided');
    });

    if (query.length === 0) return new Promise<{result: SearchResult}>( (resolve, _) => {
        resolve({
            result: {data: {
                drops: [],
                nfts: [],
                users: []
            }}
        });
    });

    return new Promise<{result: SearchResult}>( (resolve, reject) => {
        httpsClient.client.post(`/search/query`, {
            query: query
        }).then( (result : SearchResult) => {
            resolve({
                result: result
            });
        }).catch( (error) => {
            console.log(`Error searching for data ${error}`);
            reject( `Error searching for data`);
        });

    });
}


export const searchService = {
    search,
};